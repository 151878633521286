<template>
  <div class="PayInformation">
    <label v-if="isLoading">Loading...</label>
    <div v-else>
      <label v-if="!tarifvertrag">Kein Tarifvertrag hinterlegt</label>
      <verguetung-view
        v-for="verguetung in verguetungen"
        :key="verguetung.id"
        :columns="getColumns(verguetung)"
        :rows="getRows(verguetung)"
        :tableLabel="verguetung.label"
      ></verguetung-view>
      <h2 v-if="tarifvertrag">Details</h2>
      <information-view
        v-for="information in informations"
        :key="'i-' + information.id"
        :information="information"
      ></information-view>
    </div>
  </div>
</template>

<script>
  import InformationView from '../../EntgeltPage/components/InformationView.vue'
  import VerguetungView from '../../global_components/VerguetungView.vue'
  import _ from 'lodash'

  export default {
    components: {
      VerguetungView,
      InformationView},
    name: 'PayInformation',
    computed: {
      isLoading () {
        return this.$store.getters['payInfoPage/getIsLoadingTarifvertrag']
      },
      tarifvertrag () {
        return this.$store.getters['payInfoPage/tarifvertrag/getTarifVertragByDate']
      },
      verguetungen () {
        let tarifvertrag = this.$store.getters['payInfoPage/tarifvertrag/getTarifVertragByDate']
        return tarifvertrag
          ? tarifvertrag.verguetungen
          : []
      },
      informations () {
        let tarifvertrag = this.$store.getters['payInfoPage/tarifvertrag/getTarifVertragByDate']
        return tarifvertrag
          ? tarifvertrag.informations
          : []
      }
    },
    methods: {
      getColumns (verguetung) {
        let columns = []
        if (verguetung && verguetung.stufen && verguetung.stufen.length > 0) {
          columns.push({
            label: '',
            field: 'field0',
            sortable: false
          })
          verguetung.stufen.forEach((column) => {
            columns.push({
              label: column.label,
              field: 'field' + column.id,
              sortable: false,
              description: column.description
            })
          })
        }
        return columns
      },
      getRows (verguetung) {
        const columns = this.getColumns(verguetung)
        let emptyCell = { label: '' }
        const rowsArray = []
        if (verguetung && verguetung.gruppen) {
          _.forOwn(verguetung.gruppen, (row) => {
            let rowObject = {}
            row.forEach((cell, index) => {
              rowObject[columns[index].field] = cell || emptyCell
            })
            rowsArray.push(rowObject)
          })
        }
        return rowsArray
      }
    }
  }
</script>

<style lang="scss">
  .PayInformation {
    flex: 1;
    padding: 20px;
    overflow: auto;
  }
</style>
