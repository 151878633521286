<template>
  <div class="service-selector">
    <label v-if="isLoading">Lade Dienste ...</label>
    <div v-else class="content">
      <ul class="tablist">
        <li
          class="tablist-item"
          :class="{active: service === selectedService}"
          v-for="service in services"
          :key="service.offer_id"
          v-on:click="setSelectedService(service)"
          >
          <service-view :service="service" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import ServiceView from './ServiceView.vue'

  export default {
    components: {ServiceView},
    name: 'ServiceSelector',
    data () {
      return {
        selectedService: undefined
      }
    },
    computed: {
      services () {
        let services = this.$store.getters['payInfoPage/service/getServices']
        return services.sort((a, b) => a.date.localeCompare(b.date))
      },
      isLoading () {
        return this.$store.getters['payInfoPage/getIsLoadingServices']
      }
    },
    methods: {
      setSelectedService (service) {
        // ViewStateStore verwenden für den State?
        this.selectedService = service
        this.$store.dispatch('payInfoPage/loadTarifVertrag', service)
      }
    }
  }
</script>

<style lang="scss">
  .ServiceSelector {
    width: 200px;
    border: 1px solid rgba(0,0,0,0.2);
  }
</style>
