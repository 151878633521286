<template>
  <div>
    <h1>Playground Klessen</h1>
    <h6
      v-for="item in getItems"
      :key="item.id"
    >{{ item.name }}</h6>
  </div>
</template>

<script>
  export default {
    name: 'PlayGroundKlessen',
    computed: {
      getItems () {
        return this.$store.getters['play/getItems']
      }
    }
  }
</script>
