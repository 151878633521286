import Vue from 'vue'
import EqualPayAPI from '../api/EqualPayAPI'

export default {
  name: 'fristen',
  namespaced: true,
  state: {
    fristen: {},
    fristenList: [],
    employees: {},
    employeeList: [],
    monthMode: true,
    selectedFilter: {
      label: 'Alphabetisch',
      value: 1
    },
    filterItems: [
      {
        label: 'Alphabet',
        value: 1
      },
      {
        label: 'Equal Pay',
        value: 2
      },
      {
        label: 'Höchstüberlassungsdauer',
        value: 3
      }
    ],
    filterText: '',
    isLoading: false
  },
  mutations: {
    clearStore (state) {
      state.fristen = {}
      state.fristenList = []
      state.employees = {}
      state.employeeList = []
    },
    addFristen (state, fristen) {
      fristen.forEach((frist) => {
        if (!state.fristen[frist.employee_id]) {
          Vue.set(state.fristen, frist.employee_id, [])
        }
        state.fristen[frist.employee_id].push(frist)
      })
    },
    addEmployees (state, employees) {
      employees.forEach((employee) => {
        Vue.set(state.employees, employee.employee_id, employee)
        state.employeeList.push(employee.employee_id)
      })
    },
    setMonthMode (state, value) {
      state.monthMode = value
    },
    setSelectedFilter (state, filter) {
      state.selectedFilter = filter
    },
    setFilterText (state, filterText) {
      state.filterText = filterText
    },
    setIsLoading (state, value) {
      state.isLoading = value
    }
  },
  getters: {
    getFristen: state => employeeId => {
      const fristen = state.fristen[employeeId] || []
      return fristen
        .sort((frist1, frist2) => state.monthMode
          ? frist1.months_without_cooldown < frist2.months_without_cooldown
          : frist1.days_without_cooldown < frist2.days_without_cooldown
        )
    },
    getEmployees: state => {
      console.time('getEmployees')
      let i = 0
      const test = state.employeeList
        .map(id => state.employees[id])
        .filter(employee => (employee.employee_name + employee.employee_firstname + (employee.company_label_show ? employee.company_label : ''))
          .toLocaleLowerCase()
          .includes(state.filterText.toLocaleLowerCase())
        )
        .filter(employee => {
          switch (state.selectedFilter.value) {
            case 1:
              return true
            case 2:
              return state.monthMode
                ? employee.eqp_month_value > 9
                : employee.eqp_day_value > 9 * 30
            case 3:
              return state.monthMode
                ? employee.hued_month_value > 18
                : employee.hued_day_value > 18 * 30
          }
        })
        .sort((employee1, employee2) => {
          return (employee1.employee_name + employee1.employee_firstname).toLowerCase().localeCompare(
            (employee2.employee_name + employee2.employee_firstname).toLowerCase()
          )
        })
        .filter(item => i++ < 750)
      console.timeEnd('getEmployees')
      return test
    },
    getFilterItems: state => state.filterItems,
    getMonthMode: state => state.monthMode,
    getSelectedFilter: state => state.selectedFilter,
    getFilterText: state => state.filterText,
    isLoading: state => state.isLoading
  },
  actions: {
    getDataFromServer ({commit}) {
      /* let mockData = [
        {
          'employee_id': 26354,
          'employee_name': 'Stüwe',
          'employee_firstname': 'Jens',
          'employee_title': 'Herr',
          'eqp_month_value': 4.5,
          'eqp_day_value': 2.55 * 30,
          'hued_month_value': 18,
          'hued_day_value': 18 * 30,
          'fristen': [
            {
              'id': 1,
              'employee_id': 26354,
              'customer_id': 10000,
              'customer_label': 'Vivantes Kliniken',
              'customer_abbreviation': 'Vivantes Kliniken',
              'months_without_cooldown': 18,
              'days_without_cooldown': 18 * 30
            },
            {
              'id': 7,
              'employee_id': 26354,
              'customer_id': 10001,
              'customer_label': 'Vivantes Kliniken 2',
              'customer_abbreviation': 'Vivantes Kliniken 2',
              'months_without_cooldown': 9,
              'days_without_cooldown': 4.5 * 30
            },
            {
              'id': 8,
              'employee_id': 26354,
              'customer_id': 10002,
              'customer_label': 'Vivantes Kliniken 3',
              'customer_abbreviation': 'Vivantes Kliniken 3',
              'months_without_cooldown': 4.5,
              'days_without_cooldown': 2.25 * 30
            },
            {
              'id': 123,
              'employee_id': 26354,
              'customer_id': 10002,
              'customer_label': 'Vivantes Kliniken 123',
              'customer_abbreviation': 'Vivantes Kliniken 123',
              'months_without_cooldown': 16,
              'days_without_cooldown': 16 * 30
            }
          ]
        },
        {
          'employee_id': 44296,
          'employee_name': 'Wolf',
          'employee_firstname': 'Franziska',
          'employee_title': 'Frau',
          'eqp_month_value': 0,
          'eqp_day_value': 0,
          'hued_month_value': 0,
          'hued_day_value': 0,
          'fristen': []
        },
        {
          'employee_id': 1443,
          'employee_name': 'Güldag',
          'employee_firstname': 'Güzel',
          'employee_title': 'Frau',
          'eqp_month_value': 9,
          'eqp_day_value': 83,
          'hued_month_value': 12,
          'hued_day_value': 83,
          'fristen': [
            {
              'id': 2,
              'employee_id': 1443,
              'customer_id': 10000,
              'customer_label': 'Vivantes Kliniken',
              'customer_abbreviation': 'Vivantes Kliniken',
              'months_without_cooldown': 9,
              'days_without_cooldown': 83
            },
            {
              'id': 9,
              'employee_id': 1443,
              'customer_id': 10001,
              'customer_label': 'Vivantes Kliniken 1',
              'customer_abbreviation': 'Vivantes Kliniken 1',
              'months_without_cooldown': 12,
              'days_without_cooldown': 148
            }
          ]
        },
        {
          'employee_id': 31045,
          'employee_name': 'Kunkel',
          'employee_firstname': 'Gabriela ',
          'employee_title': 'Frau',
          'eqp_month_value': 2,
          'eqp_day_value': 55,
          'hued_month_value': 2,
          'hued_day_value': 55,
          'fristen': [
            {
              'id': 3,
              'employee_id': 31045,
              'customer_id': 10000,
              'customer_label': 'Vivantes Kliniken',
              'customer_abbreviation': 'Vivantes Kliniken',
              'months_without_cooldown': 2,
              'days_without_cooldown': 55
            }
          ]
        },
        {
          'employee_id': 37439,
          'employee_name': 'Steybe',
          'employee_firstname': 'Tatjana',
          'employee_title': 'Frau',
          'eqp_month_value': 0,
          'eqp_day_value': 0,
          'hued_month_value': 0,
          'hued_day_value': 0,
          'fristen': []
        },
        {
          'employee_id': 38897,
          'employee_name': 'Tahim',
          'employee_firstname': 'Tonleu',
          'employee_title': 'Herr',
          'eqp_month_value': 0,
          'eqp_day_value': 0,
          'hued_month_value': 0,
          'hued_day_value': 0,
          'fristen': []
        },
        {
          'employee_id': 41375,
          'employee_name': 'Schmiedeskamp',
          'employee_firstname': 'Sabine',
          'employee_title': 'Frau',
          'eqp_month_value': 1,
          'eqp_day_value': 30,
          'hued_month_value': 1,
          'hued_day_value': 30,
          'fristen': [
            {
              'id': 4,
              'employee_id': 41375,
              'customer_id': 10000,
              'customer_label': 'Vivantes Kliniken',
              'customer_abbreviation': 'Vivantes Kliniken',
              'months_without_cooldown': 1,
              'days_without_cooldown': 30
            }
          ]
        },
        {
          'employee_id': 41500,
          'employee_name': 'Moroz ',
          'employee_firstname': 'Natalia ',
          'employee_title': 'Frau',
          'eqp_month_value': 0,
          'eqp_day_value': 0,
          'hued_month_value': 0,
          'hued_day_value': 0,
          'fristen': []
        },
        {
          'employee_id': 46952,
          'employee_name': 'Ruschitzka',
          'employee_firstname': 'Stephanie',
          'employee_title': 'Frau',
          'eqp_month_value': 0,
          'eqp_day_value': 0,
          'hued_month_value': 0,
          'hued_day_value': 0,
          'fristen': []
        }
      ]

      // TODO: Delete for production
      commit('clearStore')
      mockData.forEach((employee) => {
        commit('addFristen', employee.fristen)
        delete employee.fristen
        commit('addEmployees', [employee])
      }) */

      commit('setIsLoading', true)
      EqualPayAPI
        .getFristen()
        .then(data => {
          console.log('Daten sind da')
          commit('clearStore')
          const employees = []
          let fristen = []
          data.data.forEach((employee) => {
            fristen = fristen.concat(employee.fristen)
            delete employee.fristen
            employees.push(employee)
          })
          commit('addEmployees', employees)
          commit('addFristen', fristen)
          commit('setIsLoading', false)
        })
    }
  }
}