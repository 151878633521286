<template>
  <div>
    <header>{{ value.format('YYYY') }}</header>
    <div class="weekRow"></div>
    <footer>

    </footer>
  </div>
</template>

<script>
  export default {
    name: 'LCalendar',
    props: {
      value: {
        type: Object,
        required: true
      }
    },
    methods: {
      save () {

      }
    }
  }
</script>
