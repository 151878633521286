<template>
    <div
        class="l-list">
        <slot name="header"></slot>
        <ul class="list">
            <li v-for="element in getElements">
                <slot v-bind="element">
                    <span class="label-default">{{ getDefaultElementLabel(element) }}</span>
                </slot>
            </li>
        </ul>
        <slot name="footer"></slot>
    </div>
</template>

<script>
/* eslint-disable */
export default {
    name: 'LList',

    props: {
        elements: {
            type: Array,
            default: []
        },
        elementLabel: {
            type: Function,
            default: void 0
        },
        filter: {
            type: Function,
            default: void 0
        },
        sort: {
            type: Function,
            default: void 0
        }
    },

    computed: {
        getElements () {
            return this.sortElements(this.filterElements(this.elements))
        }
    },

    methods: {
        filterElements (arr) {
            return this.filter
                ? arr.filter(this.filter.bind(this))
                : arr
        },
        sortElements (arr) {
            return this.sort
                ? arr.sort(this.sort.bind(this))
                : arr
        },
        getDefaultElementLabel (element) {
            return this.elementLabel
                ? this.elementLabel(element)
                : element
        }
    }
}
</script>

<style>
</style>
