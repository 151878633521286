<template>
  <div
    class="lv-input-group lv-input-group-checkbox"
    v-bind:class="{ readonly: readonly }"
  >
    <label>
      <div class="checkbox-wrapper">
        <input type="checkbox" ref="checkbox" :checked="value ? 'checked' : ''" @change="update()" v-bind:readonly="readonly">
        <div class="checkmark"></div>
      </div>
      <div class="label">{{ label }}</div>
    </label>
  </div>
</template>

<script>
  export default {
    name: 'LCheckbox',
    props: {
      /**
       * @model
       * @desc Checkbox an oder aus...
       */
      value: {
        type: Boolean,
        required: true
      },
      /**
       * Label der Checkbox
       */
      label: String,
      readonly: {
        default: false,
        type: Boolean
      }
    },

    methods: {
      update () {
        this.$emit('input', this.$refs.checkbox.checked)
      }
    }
  }
</script>

<style lang="scss">

</style>

<docs>
  ```js
  new Vue({
    data(){
      return {
        selected: true,
        label: 'Mein Label'
      }
    },
    template: `
    <div>
      <l-checkbox
        v-model="selected"
        :label="label"
      />
    </div>`
  })
  ```
</docs>
