<template>
  <div class="LMonthPicker">
    <header>
      <l-button class="decrease no-border icon stretch-height" @click="decYear"><i class="fa fa-angle-left"></i></l-button>
      <l-number v-model="selectedYear"></l-number>
      <l-button class="increase no-border icon stretch-height" @click="incYear"><i class="fa fa-angle-right"></i></l-button>
    </header>
    <section class="months">
      <label
        v-for="month, index in months"
        :key="month"
        :class="(index === selectedMonth) ? 'selected' : ''"
        v-on:click="onSelectMonth(index)"
      >{{ month }}</label>
    </section>
    <footer>
      <l-button @click="saveIt">Übernehmen</l-button>
      <l-button @click="close">Abbrechen</l-button>
    </footer>
  </div>
</template>

<script>
  import moment from '../../plugins/moment_de'

  export default {
    name: 'LMonthPicker',
    props: {
      /**
       * @model
       * Requires a moment object
       */
      value: {
        type: Object,
        required: true
      },
      /**
       * If set to true the year is not editable
       */
      fixedYear: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        months: moment.months(),
        selectedYear: parseInt(this.value.get('year')),
        selectedMonth: parseInt(this.value.get('month'))
      }
    },
    watch: {
      value (newVal, oldVal) {
        this.selectedMonth = parseInt(newVal.get('month'))
        this.selectedYear = parseInt(newVal.get('year'))
      }
    },
    methods: {
      onSelectMonth (month) {
        this.selectedMonth = month
      },
      decYear () {
        this.selectedYear--
      },
      incYear () {
        this.selectedYear++
      },
      close () {
        this.$emit('close')
      },
      saveIt () {
        this.$emit('save', moment
          .utc()
          .set('year', this.selectedYear)
          .set('month', this.selectedMonth))
      }
    }
  }
</script>

<style lang="scss">
  .LMonthPicker {
    header {
      display: flex;
      align-items: center;
      background: #f5f5f5;
      height: 48px;

      > *:not(.LButton) {
        flex: 1 1 100%;
        margin-bottom: 0;
        height: 100%;
        align-items: center;

        .input-wrapper {
          display: flex;

          input {
            border: 0;
            background: transparent;
            height: 48px;
            flex: 1 1 100%;
            text-align: center;

            &:focus {
              outline: none;
            }
          }
        }

        .title {
          display: none;
        }

        &:hover {
          background: rgba(0,0,0,0.025);
          cursor: pointer;

          input {
            color: #ff8502;
          }
        }
      }
    }

    .months {
      display: flex;
      flex-wrap: wrap;

      > label {
        padding: 1em;
        text-align: center;
        flex: 0 0 33.3%;
        border: 1px solid rgba(0,0,0,0.1);

        &:nth-child(3n + 2),
        &:nth-child(3n + 3) {
          border-left: none;
        }
        &:nth-child(n + 4) {
          border-top: none;
        }

        &:hover {
          background: rgba(0,0,0,0.025);
          cursor: pointer;
          color: #ff8502;
        }

        &.selected {
          background: #ff8502;
          color: #fff;
        }
      }
    }

    footer {
      display: flex;
      justify-content: space-between;

      .LButton {
        margin-top: 2em;
      }
    }
  }
</style>
