import Vue from 'vue'

export default {
  name: 'qualification',
  namespaced: true,
  state: {
    qualifications: {},
    qualificationList: []
  },
  mutations: {
    clear (state) {
      state.qualificationList = []
      state.qualifications = {}
    },
    setQualifications (state, qualifications) {
      state.qualifications = {}
      state.qualificationList = []
      qualifications.forEach((qualification) => {
        Vue.set(state.qualifications, qualification.id, qualification)
        state.qualificationList.push(qualification.id)
      })
    }
  },
  getters: {
    getQualifications: (state) => state.qualificationList.map(id => state.qualifications[id]),
    getQualification: (state) => (qualificationId) => state.qualifications[qualificationId]
  }
}
