<template>
  <div class="flex-el">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: 'LFlex'
  }
</script>

<style>
  .flex-el {
    flex: 1 1 20%
  }
</style>
