import Vue from 'vue'

export default {
  name: 'playground',
  namespaced: true,
  state: {
    // state
    items: {},
    itemList: []
  },
  mutations: {
    // mutations
    addItems (state, items) {
      items.forEach(item => {
        Vue.set(state.items, item.id, item)
        state.itemList.push(item.id)
      })
    }
  },
  getters: {
    // getters
    getItems (state) {
      return state.itemList.map(id => {
        return state.items[id]
      })
    }
  },
  actions: {
    // actions
  }
}
