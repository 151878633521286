<template>
  <div
    class="l-select"
    :class="{active: isActive}"
    @mousedown="onMouseDown">
    <label
      class="label"
      v-if="label">{{ label }}</label>
    <input
      ref="value"
      class="value"
      v-on:click="onClickValueLabel"
      v-on:blur="onBlur"
      :value="getValueLabel"
      :placeholder="placeholderLabel"
      readonly>
    <l-list
      ref="list"
      :elements="getElements"
      v-if="isActive"
      :filter="filterElement"
      :sort="sortElements">
      <l-text
        slot="header"
        ref="filter"
        v-if="filterEnabled"
        v-on:change="onChangeFilter"
        v-on:blur="onBlur"
        placeholderLabel=""></l-text>
        <span
          slot-scope="element"
          v-on:click="onClickCell(element)"
          class="default-cell"
          :class="{'no-value': element.__IS_NO_VALUE_LABEL }">{{ getElementLabel(element) }}</span>
    </l-list>
  </div>
</template>

<script>
  export default {
    name: 'LSelect',

    props: {
      elements: {
        type: Array,
        default: void 0,
        required: true
      },
      initialValue: {
        type: Object,
        default: void 0
      },
      label: {
        type: String,
        default: void 0
      },
      description: {
        type: String,
        default: void 0
      },
      elementLabel: {
        type: Function,
        default: void 0,
        required: true
      },
      noValueLabel: {
        type: String,
        default: void 0
      },
      placeholderLabel: {
        type: String,
        default: 'Bitte auswählen'
      },

      /**
       * Set this to render a search field above the elements list.
       */

      filterEnabled: {
        type: Boolean,
        default: false
      },

      /**
       * If filter is enabled, elements will be filtered by the elementLabel function prop by default.
       * Set this prop to use a custom filter function instead.
       * Like ordinary array.filter(), the function gets passed an element and must return bool.
       */

      filter: {
        type: Function,
        // default: this.filterByElementLabel
      },

      /**
       * Set this prop to custom sort your elements. The function gets two elements passed for
       * comparing and must return -1, 0, 1. Just like when using ordinary array.sort().
       */

      sort: {
        type: Function,
        default: void 0
      }
    },

    data () {
      return {
        isActive: false,
        value: this.initialValue,
        mouseDownRecently: false,
        filterStringsCache: []
      }
    },

    updated () {
      this.$refs.filter
        ? this.$refs.filter.focus()
        : void 0
    },

    computed: {
      getElements () {
        var elements = []

        if (this.noValueLabel) {
          elements.push({
            id: -1,
            __IS_NO_VALUE_LABEL: true
          })
        }

        return elements.concat(this.elements)
      },
      getValueLabel () {
        return this.value
          ? this.getElementLabel(this.value)
          : void 0
      },
      filterStrings: {
        get () {
          return this.filterStringsCache
        },
        set (value = '') {
          value = value.trim()

          this.filterStringsCache = value.length > 0
            ? value.split(' ').map((word) => {
              return word.toLowerCase()
            })
            : []
        }
      }
    },

    methods: {
      getElementLabel (element) {
        return element.__IS_NO_VALUE_LABEL
          ? this.noValueLabel
          : this.elementLabel(element)
      },
      setActive () {
        this.isActive = true
        this.mouseDownRecently = this.filterEnabled

        this.$refs.filter
          ? this.$refs.filter.focus()
          : this.$refs.value.focus()
      },
      setInactive () {
        this.isActive = false
        this.$refs.value.blur()

        this.$refs.filter
          ? this.$refs.filter.blur()
          : void 0
      },
      update (element) {
        this.value = element.__IS_NO_VALUE_LABEL
          ? void 0
          : element

        this.filterStrings = void 0
        this.$emit('change', this.value, this)
        this.$emit('input', this.value, this)
      },

      onMouseDown (e) {
        this.isActive
          ? this.mouseDownRecently = true
          : void 0
      },
      onClickCell (element, e) {
        this.update(element)
        this.setInactive()
      },
      onClickValueLabel (e) {
        if (this.mouseDownRecently && this.filterEnabled) {
          return
        }

        this.isActive
          ? this.setInactive()
          : this.setActive()
      },
      anyInputHasFocus () {
        return this.$refs.value === document.activeElement ||
        (this.$refs.filter && this.$refs.filter.isFocus())
      },
      onBlur (e) {
        if (this.isActive && (this.mouseDownRecently || this.anyInputHasFocus())) {
          this.setActive()
          this.mouseDownRecently = false
        } else {
          this.setInactive()
        }
      },

      onChangeFilter (value, emitter) {
        this.filterStrings = value
      },

      filterElement (element) {
        return this.filter
          ? this.filter(element)
          : this.filterByElementLabel(element)
      },
      sortElements (e1, e2) {
        return this.sort
          ? this.sort.apply(this, arguments)
          : 0
      },

      filterByElementLabel (element) {
        var needles = this.filterStrings
        var haystack = this.getElementLabel(element).toLowerCase()

        // For the sake of performance, use ordinary for. for..of or reduce are slow as fuck.

        for (var i = 0; i < needles.length; i++) {
          var needle = needles[i].toLowerCase()
          if (haystack.indexOf(needle) === -1) {
            return false
          }
        }

        return true
      }
    }
  }
</script>

<style lang="scss">
  @import '~@/css/common/common';

  .l-select {
  	position: relative;

  	> label.label {
  		display: block;
  		font-size: $__FONT_SIZE_LABEL;
  		color: $__COLOR_LABEL_INACTIVE;
  	}

  	> input.value {
  		display: block;
  		outline: none;
  		cursor: default;
  		width: 100%;
  		border: 0;
  		border-bottom: 1px solid $__COLOR_FIELD_BORDER_BLUR;
  		line-height: 1.5em;
  		height: $__HEIGHT_UNIT / 1.25;
  		background: transparent;

  		&:focus {
  			outline: none;
  		}
  	}

  	.l-list {
  	  position: absolute;
  	  left: 0;
  	  bottom: -2px;
  	  transform: translate(0, 100%);
  	  width: 100%;
      z-index: 1000;
  	  background: $__COLOR_FIELD_BACKGROUND_FOCUS;
  	  // border-bottom: 1px solid $__COLOR_FIELD_BORDER_FOCUS;
  	  box-shadow: 0px 5px $__HEIGHT_UNIT / 2.5 rgba($__COLOR_TEXT_EDITABLE, 0.2);

  	  > .lv-input-group-textfield {
  		  margin-bottom: 0;

  		  .title {
  			  margin: 0;
  		  }

  		  .input-wrapper {
          padding-left: $__HEIGHT_UNIT / 4;
          padding-right: $__HEIGHT_UNIT / 4;
          border-bottom: 1px solid $__COLOR_FIELD_BORDER_BLUR;
          display: flex;

          &:before {
            height: 100%;
            width: $__HEIGHT_UNIT / 2;
            content: '\f002';
            font-family: "FontAwesome";
            display: flex;
            align-items: center;
            color: $__COLOR_LABEL_INACTIVE;
          }

          > input[type="text"] {
            flex: 1 1 100%;
    			  border-bottom: none !important;
            padding: 0 !important;
            background: transparent !important;
    		  }
        }
  	  }

  	  > ul {
  		  max-height: $__HEIGHT_UNIT * 4;
  		  overflow-x: hidden;
  		  overflow-y: auto;

  		  > li {
  			  width: 100%;

  			  &:hover {
  				  color: $__COLOR_FIELD_BORDER_FOCUS;
  				  background: darken($__COLOR_FIELD_BACKGROUND_FOCUS, 2);
  			  }

  			  * {
  				  cursor: default;
  				  user-select: none;
  			  }

  			  > .default-cell {
  				  padding: $__HEIGHT_UNIT / 6 $__HEIGHT_UNIT / 4;
  				  display: block;
  				  width: 100%;

  				  &.no-value {
  					  color: $__COLOR_LABEL_INACTIVE;

              &:hover {
                color: $__COLOR_LABEL_FOCUS;
              }
  				  }
  			  }
  		  }
  	  }
  	}

  	@extend .icon-arrow-down;
  	&.active {
  		@extend .icon-arrow-up;
  		> label.label {
  			color: $__COLOR_LABEL_FOCUS;
  		}

      > input.value {
        border-bottom-color: $__COLOR_FIELD_BORDER_FOCUS;
      }
  	}

  	&:after {
  		position: absolute;
  		bottom: 1px;
  		top: auto !important;
  		right: 0;
  		height: $__HEIGHT_UNIT / 1.25;
  		line-height: $__HEIGHT_UNIT / 1.25 !important;
  		pointer-events: none;
  		display: block !important;
  	}
  }
</style>

<docs>
</docs>
