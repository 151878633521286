import EqualPayAPI from '../api/EqualPayAPI'
import Vue from 'vue'

export default {
  name: 'bereich',
  namespaced: true,
  state: {
    bereiche: {},
    bereichList: []
  },
  mutations: {
    setBereiche (state, data) {
      state.bereiche = {}
      state.bereichList = []
      data.forEach((bereich) => {
        Vue.set(state.bereiche, bereich.id, bereich)
        state.bereichList.push(bereich.id)
      })
    }
  },
  getters: {
    getBereiche (state, getters, rootState, rootGetters) {
      let activeOrganisation = rootGetters.getActiveOrganisationId
      return state.bereichList
        .map((id) => state.bereiche[id])
        .filter((bereich) => bereich.customer_id === activeOrganisation)
    }
  },
  actions: {
    resetActiveBereich ({commit, getters}) {
      let bereiche = getters['getBereiche']
      if (bereiche && bereiche.length > 0) {
        commit('setActiveBereich', bereiche[0].id, { root: true })
      }
    },
    getFromServer ({commit, rootGetters}) {
      let activeOrganisationId = rootGetters.getActiveOrganisationId
      EqualPayAPI
        .getBereiche(activeOrganisationId)
        .then((response) => commit('setBereiche', response.data))
        .catch((error) => console.log(error))
    }
  }
}
