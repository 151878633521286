<template>
  <transition name="LDialog">
    <div class="LDialog-mask" @click="close" v-show="show">
      <div class="LDialog-container" @click.stop>
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<script>
  export default {
    name: 'LDialog',
    props: ['show'],
    methods: {
      close: function () {
        this.$emit('close')
      }
    },
    mounted: function () {
      document.addEventListener('keydown', (e) => {
        if (this.show && e.keyCode === 27) {
          this.close()
        }
      })
    }
  }
</script>

<style lang="scss">
  .LDialog-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    transition: opacity .3s ease;
  }

  .LDialog-container {
    width: 300px;
    margin: 40px auto 0;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
    font-family: Helvetica, Arial, sans-serif;
  }

  .LDialog-enter {
    opacity: 0;
  }

  .LDialog-leave-active {
    opacity: 0;
  }

  .LDialog-enter .LDialog-container,
  .LDialog-leave-active .LDialog-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
</style>
