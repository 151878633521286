<template>
  <div class="LMonthSelector">
    <l-button class="decrease no-border icon stretch-height" @click="dec"><i class="fa fa-angle-left"></i></l-button>
    <l-button class="select no-border stretch-height" @click="showPicker = !showPicker">{{monthString}}</l-button>
    <l-button class="increase no-border icon stretch-height" @click="inc"><i class="fa fa-angle-right"></i></l-button>
    <l-dialog
      class="dialog-datepicker"
      :show="showPicker"
      @close="showPicker = !showPicker"
    >
      <l-month-picker
        v-model="value"
        v-on:close="showPicker = !showPicker"
        v-on:save="save"
      />
    </l-dialog>
  </div>
</template>

<script>
  export default {
    name: 'LMonthSelector',
    props: {
      /**
       * @model
       * value must be an moment object
       */
      value: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        showPicker: false
      }
    },
    methods: {
      inc () {
        this.$emit('input', this.value.add(1, 'month').clone())
      },
      dec () {
        this.$emit('input', this.value.subtract(1, 'month').clone())
      },
      save (value) {
        this.$emit('input', value)
        this.showPicker = false
      }
    },
    computed: {
      monthString () {
        return this.value.format('MMMM YYYY')
      }
    }
  }
</script>

<style lang="scss">
  .LMonthSelector {
    height: 48px;
    display: flex;
    align-items: center;

    > .select {
      flex: 1 1 100%;
      width: 100%;
    }
  }

  .dialog-datepicker {
   .LDialog-container {
      width: auto;
      max-width: 400px;
    }
  }
</style>
