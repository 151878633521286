<script>
  // eslint-disable-next-line no-unused-vars
  import FristBar from './FristBar.vue'
  import Vue from 'vue'
  import moment from 'moment'

  export default {
    functional: true,
    name: 'FristRow',
    render (createElement, ctx) {
      return createElement(
        'div',
        {
          'class': 'fristRow'
        },
        [
          createElement('div', {}, [
            createElement('label', ctx.props.frist.customer_label),
            createElement('label', 'Fristbeginn: ' + moment(ctx.props.frist.start_date).format('D.M.YYYY'))
          ]),
          createElement('FristBar', Vue.util.extend({}, ctx))
        ]
      )
    }
  }
</script>

<style lang="scss">
  @import '../../css/common/colors';

  .fristRow {
    padding: 12px 24px;
    position: relative;
    color: $color_grayDark;

    &:not(:last-child) {
      border-bottom: 1px solid #e5e5e5;
    }

    > div {
      display: inline-block;
      width: 250px;
      padding-left: 1em;
      > label {
        display: block;
      }
    }

    &:hover {
      background: #f0f0f0;

      .fristBar {
        > div {
          background: darken($color_greenDark, 0);
        }
      }
    }
  }
</style>
