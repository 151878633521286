import Vue from 'vue'

export default {
  name: 'information',
  namespaced: true,
  state: {
    informations: {},
    informationsList: []
  },
  mutations: {
    clearStore (state) {
      state.informations = {}
      state.informationsList = []
    },
    addInformations (state, informations) {
      informations.forEach((info) => {
        Vue.set(state.informations, info.id, info)
        state.informationsList.push(info.id)
      })
    }
  },
  getters: {
    getInformations (state, getters, rootState, rootGetters) {
      let activeTarifvertragId = rootGetters.getActiveTarifvertragId
      return state.informationsList
        .map((id) => state.informations[id])
        .filter((info) => info.tarifvertrag_id === activeTarifvertragId)
    }
  },
  actions: {}
}
