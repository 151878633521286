import EqualPayApi from '../api/EqualPayAPI'
import Vue from 'vue'

export default {
  name: 'organisation',
  namespaced: true,
  state: {
    organisationen: {},
    organisationList: []
  },
  mutations: {
    setOrganisations (state, data) {
      state.organisationen = {}
      state.organisationList = []
      data.forEach((org) => {
        Vue.set(state.organisationen, org.id, org)
        state.organisationList.push(org.id)
      })
    }
  },
  getters: {
    getOrganisations (state) {
      return state.organisationList.map((id) => state.organisationen[id])
    }
  },
  actions: {
    getFromServer ({commit}) {
      EqualPayApi
        .getOrganisations()
        .then((response) => commit('setOrganisations', response.data))
        .catch((error) => console.log(error))
    }
  }
}
