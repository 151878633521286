import Vue from 'vue'
import _ from 'lodash'

export default {
  name: 'verguetung',
  namespaced: true,
  state: {
    verguetung: {},
    verguetungList: []
  },
  mutations: {
    clearStore (state) {
      state.verguetung = {}
      state.verguetungList = []
    },
    addVerguetungen (state, verguetungen) {
      verguetungen.forEach((verguetung) => {
        console.log(verguetung.id)
        Vue.set(state.verguetung, verguetung.id, verguetung)
        state.verguetungList.push(verguetung.id)
      })
    }
  },
  getters: {
    getLabel: (state) => (verguetungId) => state.verguetung[verguetungId].label,
    getVerguetungen (state, getters, rootState, rootGetters) {
      let activeTarifvertragId = rootGetters.getActiveTarifvertragId
      return state.verguetungList
        .map((id) => state.verguetung[id])
        .filter((verguetung) => verguetung.tarifvertrag_id === activeTarifvertragId)
    },
    getColumns: (state) => (verguetungId) => {
      let verguetung = state.verguetung[verguetungId]
      let columns = []
      if (verguetung && verguetung.stufen && verguetung.stufen.length > 0) {
        columns.push({
          label: '',
          field: 'field0',
          sortable: false
        })
        verguetung.stufen.forEach((column) => {
          columns.push({
            label: column.label,
            field: 'field' + column.id,
            sortable: false,
            description: column.description
          })
        })
      }
      return columns
    },
    getRows: (state, getters) => (verguetungId, selectedQuali) => {
      const columns = getters.getColumns(verguetungId)
      let verguetung = state.verguetung[verguetungId]
      let emptyCell = { label: '' }
      const rowsArray = []
      if (verguetung && verguetung.gruppen) {
        _.forOwn(verguetung.gruppen, (row) => {
          let rowObject = {}
          row.forEach((cell, index) => {
            rowObject[columns[index].field] = cell || emptyCell
          })
          rowsArray.push(rowObject)
        })
      }
      return selectedQuali && selectedQuali !== -1
        ? rowsArray.filter(row => row.field0.qualification_ids.indexOf(selectedQuali) !== -1)
        : rowsArray
    }
  }
}
