<template>
  <l-row>
    <organisation-list></organisation-list>
    <tarifvertrag-view></tarifvertrag-view>
  </l-row>
</template>

<script>
  import OrganisationList from './components/OrganisationList.vue'
  import TarifvertragView from './components/TarifvertragView.vue'

  export default {
    name: 'EntgeltPage',
    components: {
      TarifvertragView,
      OrganisationList
    }
  }
</script>
