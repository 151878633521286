import Vue from 'vue'

export default {
  name: 'employee',
  namespaced: true,
  state: {
    employees: {},
    employeeList: []
  },
  mutations: {
    clearStore (state) {
      state.employees = {}
      state.employeeList = []
    },
    addEmployees (state, employees) {
      employees.forEach((employee) => {
        Vue.set(state.employees, employee.employee_id, employee)
        state.employeeList.push(employee.employee_id)
      })
    }
  },
  getters: {
    getEmployees: state => state.employeeList.map(id => state.employees[id])
  },
  actions: {
    getDataFromServer ({commit}) {
      let mockData = [
        {
          'employee_id': 26354,
          'employee_name': 'Stüwe',
          'employee_firstname': 'Jens',
          'employee_title': 'Herr',
          'fristen': [
            {
              'id': 1,
              'employee_id': 26354,
              'customer_id': 10000,
              'customer_label': 'Vivantes Kliniken',
              'customer_abbreviation': 'Vivantes Kliniken',
              'months_without_cooldown': 18,
              'days_without_cooldown': 18 * 30
            },
            {
              'id': 7,
              'employee_id': 26354,
              'customer_id': 10001,
              'customer_label': 'Vivantes Kliniken 2',
              'customer_abbreviation': 'Vivantes Kliniken 2',
              'months_without_cooldown': 9,
              'days_without_cooldown': 4.5 * 30
            },
            {
              'id': 8,
              'employee_id': 26354,
              'customer_id': 10002,
              'customer_label': 'Vivantes Kliniken 3',
              'customer_abbreviation': 'Vivantes Kliniken 3',
              'months_without_cooldown': 4.5,
              'days_without_cooldown': 2.25 * 30
            },
            {
              'id': 123,
              'employee_id': 26354,
              'customer_id': 10002,
              'customer_label': 'Vivantes Kliniken 123',
              'customer_abbreviation': 'Vivantes Kliniken 123',
              'months_without_cooldown': 16,
              'days_without_cooldown': 16 * 30
            }
          ]
        },
        {
          'employee_id': 44296,
          'employee_name': 'Wolf',
          'employee_firstname': 'Franziska',
          'employee_title': 'Frau',
          'fristen': []
        },
        {
          'employee_id': 1443,
          'employee_name': 'Güldag',
          'employee_firstname': 'Güzel',
          'employee_title': 'Frau',
          'fristen': [
            {
              'id': 2,
              'employee_id': 1443,
              'customer_id': 10000,
              'customer_label': 'Vivantes Kliniken',
              'customer_abbreviation': 'Vivantes Kliniken',
              'months_without_cooldown': 9,
              'days_without_cooldown': 83
            },
            {
              'id': 9,
              'employee_id': 1443,
              'customer_id': 10001,
              'customer_label': 'Vivantes Kliniken 1',
              'customer_abbreviation': 'Vivantes Kliniken 1',
              'months_without_cooldown': 12,
              'days_without_cooldown': 148
            }
          ]
        },
        {
          'employee_id': 31045,
          'employee_name': 'Kunkel',
          'employee_firstname': 'Gabriela ',
          'employee_title': 'Frau',
          'fristen': [
            {
              'id': 3,
              'employee_id': 31045,
              'customer_id': 10000,
              'customer_label': 'Vivantes Kliniken',
              'customer_abbreviation': 'Vivantes Kliniken',
              'months_without_cooldown': 0,
              'days_without_cooldown': 0
            }
          ]
        },
        {
          'employee_id': 37439,
          'employee_name': 'Steybe',
          'employee_firstname': 'Tatjana',
          'employee_title': 'Frau',
          'fristen': []
        },
        {
          'employee_id': 38897,
          'employee_name': 'Tahim',
          'employee_firstname': 'Tonleu',
          'employee_title': 'Herr',
          'fristen': []
        },
        {
          'employee_id': 41375,
          'employee_name': 'Schmiedeskamp',
          'employee_firstname': 'Sabine',
          'employee_title': 'Frau',
          'fristen': [
            {
              'id': 4,
              'employee_id': 41375,
              'customer_id': 10000,
              'customer_label': 'Vivantes Kliniken',
              'customer_abbreviation': 'Vivantes Kliniken',
              'months_without_cooldown': 1,
              'days_without_cooldown': 1
            }
          ]
        },
        {
          'employee_id': 41500,
          'employee_name': 'Moroz ',
          'employee_firstname': 'Natalia ',
          'employee_title': 'Frau',
          'fristen': []
        },
        {
          'employee_id': 46952,
          'employee_name': 'Ruschitzka',
          'employee_firstname': 'Stephanie',
          'employee_title': 'Frau',
          'fristen': []
        }
      ]
      commit('clearStore')
      setTimeout(() => {
        mockData.forEach((employee) => {
          commit('fristen/addFristen', employee.fristen, { root: true })
          delete employee.employees
          commit('addEmployees', [employee])
        })
      }, 1000)
    }
  }
}
