<template>
  <div class="service-item">
    <label class="service-item-date">{{service.date | formatServiceDate }}</label>
    <label class="service-item-qualification">{{ service.qualification_label }}</label>
  </div>
</template>

<script>
  import moment from '../../plugins/moment_de'

  export default {
    name: 'ServiceView',
    props: {
      service: {
        type: Object,
        required: true
      }
    },
    filters: {
      formatServiceDate (dateString) {
        return moment(dateString).format('DD.MM.YYYY')
      }
    }
  }
</script>

<style lang="scss">
  .service-item-date,
  .service-item-qualification {
    display: block;
  }
  .service-item-qualification {
    color: #888;
    font-size: 0.9em;
  }
</style>
