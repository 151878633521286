<template>
  <div class="lv-input-group lv-input-group-preformat">
    <label class="title">{{ label }}</label>
    <pre class="value" v-html="value"></pre>
  </div>
</template>

<script>
  export default {
    name: 'LPreformat',
    props: ['value', 'label'],
    methods: {
    }
  }
</script>
