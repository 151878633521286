import Vue from 'vue'
import Router from 'vue-router'

import EntgeltPage from './EntgeltPage/EntgeltPage.vue'
import FristenPage from './FristenPage/FristenPage.vue'
import PaymentInformationPage from './PaymentInformationPage/PaymentInformationPage.vue'
import PlaygroundTwinkler from './PlayPage/PlaygroundTwinkler.vue'
import PlaygroundKlessen from './PlayPage/PlaygroundKlessen.vue'

Vue.use(Router)

const router = new Router({
  base: process.env.VUE_APP_ROUTER_BASE,
  /* mode: 'history', */
  routes: [
    {
      name: 'EntgeltPage',
      path: '/',
      component: EntgeltPage
    },
    {
      name: 'FristenPage',
      path: '/fristen',
      component: FristenPage
    },
    {
      name: 'PaymentInformationPage',
      path: '/payinfo',
      component: PaymentInformationPage
    },
    {
      name: 'PlayGroundTwinkler',
      path: '/twinkler',
      component: PlaygroundTwinkler
    },
    {
      name: 'PlayGroundKlessen',
      path: '/klessen',
      component: PlaygroundKlessen
    },
    {
      path: '*',
      redirect: '/'
    }
  ]
})

export default router
