import EqualPayAPI from '../api/EqualPayAPI'
import Vue from 'vue'
import moment from '../plugins/moment_de'

export default {
  name: 'tarifvertrag',
  namespaced: true,
  state: {
    tarifvertraege: {},
    tarifvertragList: []
  },
  mutations: {
    clearStore (state) {
      state.tarifvertraege = {}
      state.tarifvertragList = []
    },
    addVertrag (state, vertrag) {
      Vue.set(state.tarifvertraege, vertrag.id, vertrag)
      state.tarifvertragList.push(vertrag.id)
    }
  },
  getters: {
    getTarifvertraege (state, getters, rootState, rootGetters) {
      let activeBereichId = rootGetters.getActiveBereichId
      return state.tarifvertragList
        .map((id) => state.tarifvertraege[id])
        .filter((vertrag) => vertrag.bereich_id === activeBereichId)
    },
    getTarifvertrag: (state, getters, rootState, rootGetters) => (vertragId) => {
      return state.tarifvertragList
        .map((id) => state.tarifvertraege[id])
        .find((vertrag) => vertrag.id === vertragId)
    },
    getCurrentTarifvertragId (state) {
      return state.tarifvertragList.find((vertragId) => {
        const start = moment(state.tarifvertraege[vertragId].valid_from)
        const finish = moment(state.tarifvertraege[vertragId].valid_till)
        return moment().isBetween(start, finish, 'days', '[]')
      })
    },
    getLastTarifvertragId (state) {
      let lastVertragId = state.tarifvertragList[0]
      if (!lastVertragId) return
      let lastMoment = moment(state.tarifvertraege[lastVertragId].valid_till)
      state.tarifvertragList.forEach((vertragId) => {
        let currentMoment = moment(state.tarifvertraege[vertragId].valid_till)
        if (currentMoment.isAfter(lastMoment)) {
          lastMoment = currentMoment
          lastVertragId = vertragId
        }
      })
      return lastVertragId
    }
  },
  actions: {
    getFromServer ({commit, dispatch, rootGetters, getters}) {
      let activeBereichId = rootGetters.getActiveBereichId
      EqualPayAPI
        .getTarifvertraege(activeBereichId)
        .then(function (response) {
          commit('clearStore')
          commit('verguetung/clearStore', undefined, { root: true })
          commit('information/clearStore', undefined, { root: true })
          commit('qualification/clearStore', undefined, { root: true })
          commit('qualification/addQualifications', response.data.qualifications, { root: true })
          response.data.tarifvertraege.forEach((vertrag) => {
            commit('addVertrag', {
              id: vertrag.id,
              valid_from: vertrag.valid_from,
              valid_till: vertrag.valid_till,
              bereich_id: vertrag.bereich_id,
              workhours: vertrag.workhours
            })
            commit('verguetung/addVerguetungen', vertrag.verguetungen, { root: true })
            commit('information/addInformations', vertrag.informations, { root: true })
          })
          let activeTarifvertragId = getters.getCurrentTarifvertragId || getters.getLastTarifvertragId
          commit('setActiveTarifvertragId', activeTarifvertragId, { root: true })
        })
        .catch(error => console.log(error))
    }
  }
}
