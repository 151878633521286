<template>
  <div class="employeeFrist" :class="{active: isExpanded, 'has-fristen': hasFristen}">
    <div class="summary-row" @click="expand = !expand">
      <label>{{ employee.employee_name + ', ' + employee.employee_firstname + (employee.company_label_show ? ( ' (' + employee.company_label + ')' ) : '') }}</label>
      <span class="badge badge-grey" v-if="hasFristen">{{getFristen.length}}</span>
      <span class="badge badge-yellow" v-if="hasCriticalEQP" title="Equal Pay">EQP</span>
      <span class="badge badge-red" v-if="hasCriticalHUED" title="Höchstüberlassungsdauer">HÜD</span>
      <div class="hottest-frist" v-if="hasFristen && !isExpanded">
        <FristBar
          :frist="getMostImportantFrist"
          :showLabel="true"
          :monthMode="monthMode"
        />
      </div>
      <div>
        <label class="startMonth" v-if="!isExpanded">{{startDate}}</label>
      </div>
      </div>
    <frist-row
      v-for="frist in getExpandFristen"
      :key="frist.id"
      :frist="frist"
      :monthMode="monthMode"
    />
  </div>
</template>

<script>
  import FristBar from './FristBar.vue'
  import FristRow from './FristRow.vue'
  import config from '../../config'
  import moment from 'moment'

  export default {
    components: {
      FristBar,
      FristRow
    },
    name: 'EmployeeFrist',
    props: ['employee'],
    data () {
      return {
        expand: false
      }
    },
    watch: {
      selectedFilter (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.expand = false
        }
      }
    },
    computed: {
      startDate () {
        return this.getMostImportantFrist ? 'Fristbeginn ' + moment(this.getMostImportantFrist.start_date).format('D.M.YYYY') : ''
      },
      monthMode () {
        return this.$store.getters['fristen/getMonthMode']
      },
      isExpanded () {
        return this.$data.expand
      },
      selectedFilter () {
        return this.$store.getters['fristen/getSelectedFilter']
      },
      getFristen () {
        return this.$store.getters['fristen/getFristen'](this.employee.employee_id)
      },
      hasFristen () {
        return this.getFristen && this.getFristen.length
      },
      getMostImportantFrist () {
        return this.getFristen[0]
      },
      getExpandFristen () {
        return this.$data.expand
          ? this.getFristen
          : []
      },
      // EQP = EqualPay
      hasCriticalEQP () {
        const fristen = this.getFristen
        const monthMode = this.monthMode
        if (monthMode) {
          return fristen.find(frist => frist.months_without_cooldown >= config.eqp_month_border && frist.months_without_cooldown <= 9)
        } else {
          return fristen.find(frist => frist.days_without_cooldown >= config.eqp_day_border && frist.days_without_cooldown <= 9 * 30)
        }
      },
      // HUED = HöchstÜberlassungsDauer
      hasCriticalHUED () {
        const fristen = this.getFristen
        const monthMode = this.monthMode

        if (monthMode) {
          return fristen.find(frist => frist.months_without_cooldown >= config.hued_month_border && frist.months_without_cooldown <= 18)
        } else {
          return fristen.find(frist => frist.days_without_cooldown >= config.hued_day_border && frist.days_without_cooldown <= 18 * 30)
        }
      },
    }
  }
</script>

<style lang="scss">
  @import '../../css/common/common';
  @import '../../css/common/colors';

  .startDay {
    color: $color_grayDark;
  }
  .startMonth{
    color: $color_grayDark;
  }
  .employeeFrist {
    position: relative;
    width: 100%;
    border-bottom: 1px solid #e5e5e5;

    .summary-row {
      position: relative;
      padding: 12px 24px;

      .hottest-frist {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        bottom: 0;
      }
    }

    &.has-fristen {
      &:hover,
      &.active {
        &:after {
          content: '';
          position: absolute;
          left: 0;
          width: 4px;
          height: 100%;
          top: 0;
          background: #ff8502;
        }
      }

      &:hover {
        .summary-row {
          background: rgba(0,0,0,0.025);
          cursor: pointer;

          .fristBar {
            > div {
              background: darken($color_greenDark, 0);
            }
          }
        }
      }

      &.active {
        .summary-row {
          background: #f0f0f0;
        }
      }
    }
  }
</style>
