export default {
  name: 'viewState',
  namespaced: false,
  state: {
    activeOrganisationId: undefined,
    activeBereichId: undefined,
    activeTarifvertragId: undefined,
    online: navigator.onLine,
    showMonth: true
  },
  mutations: {
    setActiveOrganisationId (state, organisationId) {
      state.activeOrganisationId = organisationId
    },
    setActiveBereichId (state, bereichId) {
      state.activeBereichId = bereichId
    },
    setActiveTarifvertragId (state, tarifvertragId) {
      state.activeTarifvertragId = tarifvertragId
    },
    online (state) {
      state.online = navigator.onLine
    },
    setShowMonth (state, value) {
      state.showMonth = value
    }
  },
  getters: {
    getActiveOrganisationId (state) {
      return state.activeOrganisationId
    },
    getActiveBereichId (state) {
      return state.activeBereichId
    },
    getActiveTarifvertragId (state) {
      return state.activeTarifvertragId
    },
    isOnline (state) {
      return state.online
    },
    getShowMonth (state) {
      return state.showMonth
    }
  }
}
