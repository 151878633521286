<template>
  <div class="organisationMenu">
    <h1><span>Equal</span><span>Pay</span></h1>
    <div class="tablist">
      <div
        class="organisation"
        v-for="organisation in getOrganisations"
        v-bind:key="organisation.id"
        v-bind:class="activeOrganisationId === organisation.id ? 'active' : ''">
        <div
          class="content"
          v-on:click="setActiveOrganisation(organisation.id)">
            <label>{{ organisation.label }}</label>
            <div
            class="icon expand"
            v-bind:class="activeOrganisationId === organisation.id && getBereiche.length === 0 ? 'loading' : ''"></div>
        </div>
        <div
          class="bereich"
          v-if="activeOrganisationId === organisation.id"
          v-for="bereich in getBereiche"
          v-bind:key="bereich.id"
          v-bind:class="activeBereichId === bereich.id ? 'active' : ''">
          <div
            class="content"
            v-on:click="setActiveBereich(bereich.id)">
              <label>{{ bereich.label }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'organisationList',
    created () {
      this.$store.dispatch('organisation/getFromServer')
    },
    computed: {
      getOrganisations () {
        return this.$store.getters['organisation/getOrganisations']
      },
      activeOrganisationId () {
        return this.$store.getters.getActiveOrganisationId
      },
      getBereiche () {
        return this.$store.getters['bereich/getBereiche']
      },
      activeBereichId () {
        return this.$store.getters.getActiveBereichId
      }
    },
    methods: {
      setActiveOrganisation (id) {
        id = id === this.activeOrganisationId
          ? undefined
          : id

        this.$store.commit('setActiveOrganisationId', id)

        id !== undefined
          ? this.$store.dispatch('bereich/getFromServer')
          : void (0)

        this.resetSelections()
      },

      setActiveBereich (id) {
        this.resetSelections()
        this.$store.commit('setActiveBereichId', id)
        this.$store.dispatch('tarifvertrag/getFromServer', id)
      },

      resetSelections () {
        this.$store.commit('setActiveBereichId')
        this.$store.commit('setActiveTarifvertragId')
      }
    }
  }
</script>

<style lang="scss">
</style>
