import Vue from 'vue'
import moment from '../../plugins/moment_de'

export default {
  name: 'tarifvertrag',
  namespaced: true,
  state: {
    tarifvertraege: {},
    tarifvertragList: []
  },
  mutations: {
    clear (state) {
      state.tarifvertraege = {}
      state.tarifvertragList = []
    },
    setTarifvertraege (state, tarifvertraege) {
      state.tarifvertraege = {}
      state.tarifvertragList = []
      tarifvertraege.forEach((tarifvertrag) => {
        Vue.set(state.tarifvertraege, tarifvertrag.id, tarifvertrag)
        state.tarifvertragList.push(tarifvertrag.id)
      })
    }
  },
  getters: {
    getCurrentTarifvertrag (state) {
      return state.tarifvertragList
        .map(vertragId => state.tarifvertraege[vertragId])
        .find((vertrag) => {
          let start = moment(vertrag.valid_from)
          let finish = moment(vertrag.valid_till)
          return moment().isBetween(start, finish, 'days', '[]')
        })
    },

    getTarifVertragByDate (state, getters, rootState, rootGetters) {
      const selectedService = rootGetters['payInfoPage/getSelectedService']
      return state.tarifvertragList
          .map(vertragId => state.tarifvertraege[vertragId])
          .find((vertrag) => {
            let start = moment(vertrag.valid_from)
            let finish = moment(vertrag.valid_till)
            return moment(selectedService.date).isBetween(start, finish, 'days', '[]')
          })
    }
  }
}
