import Vue from 'vue'

export default {
  name: 'qualification',
  namespaced: true,
  state: {
    qualifications: {},
    qualificationList: []
  },
  mutations: {
    clearStore (state) {
      state.qualifications = {}
      state.qualificationList = []
    },
    addQualifications (state, qualifications) {
      qualifications.forEach((quali) => {
        Vue.set(state.qualifications, quali.id, quali)
        state.qualificationList.push(quali.id)
      })
    }
  },
  getters: {
    getQualifications: (state) => state.qualificationList
      .map(id => state.qualifications[id])
      .sort((a, b) => a.name.localeCompare(b.name)),
    getQualification: (state) => (qualificationId) => state.qualifications[qualificationId]
  }
}
