<template>
  <div class="lv-input-group">
    <label>{{ label }}</label>
    <textarea ref="textarea" :rows="rows" :cols="cols" v-model="text"></textarea>
  </div>
</template>

<script>
  export default {
    name: 'LTextarea',
    props: ['value', 'label', 'rows', 'cols'],
    computed: {
      text: {
        get: function () { return this.value },
        set: function (value) { this.$emit('input', value) }
      }
    },
    methods: {
      update () {
        this.$emit('input', this.$refs.textarea.value)
      }
    }
  }
</script>
