<template>
  <div class="paymentInformationPage">
    <l-month-selector v-model="month" class="bar" />
    <div class="content">
      <employee-selector ref="employeeSelector" />
      <service-selector ref="serviceSelector" />
      <pay-information ref="payInformation" />
    </div>
  </div>
</template>

<script>
  import EmployeeSelector from './components/EmployeeSelector.vue'
  import ServiceSelector from './components/ServiceSelector.vue'
  import PayInformation from './components/PayInformation.vue'

  export default {
    components: {
      PayInformation,
      ServiceSelector,
      EmployeeSelector
    },
    name: 'PaymentInformationPage',
    created () {
      this.$store.dispatch('payInfoPage/loadEmployees')
    },
    watch: {
      month () {
        this.$store.dispatch('payInfoPage/loadEmployees')
      }
    },
    computed: {
      month: {
        get () { return this.$store.getters['payInfoPage/getSelectedMonth'] },
        set (value) { this.$store.commit('payInfoPage/setSelectedMonth', value) }
      }
    }
  }
</script>

<style lang="scss">
  .paymentInformationPage {
    display: flex;
    flex-direction: column;
    width: 100%;

    > .content {
      flex: 1 1 100%;
      height: 100%;
      display: flex;

      > * {
        height: 100%;
      }
    }
  }

  .employee-selector,
  .service-selector {
    background: #f5f5f5;
    flex: 0 0 220px;
    height: 100%;
    position: relative;

    &:after {
      position: absolute;
      content: '';
      top: 0;
      right: -3px;
      height: 100%;
      width: 3px;
      background: linear-gradient(to right, rgba(0, 0, 0, 0.1), transparent);
      z-index: 1;
    }

    > .content {
      height: 100%;
      width: 100%;

      display: flex;
      flex-direction: column;

      > .tablist {
        flex: 1 1 100%;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;

        > .tablist-item {
          display: block;
          position: relative;
          color: #656565;
          padding: 1em;
          border-top: 1px solid #e5e5e5;

          &:last-child {
            border-bottom: 1px solid #e5e5e5;
          }

          &:hover,
          &.active {
            background: #F0F0F0;
            cursor: default;

            &:after {
              content: '';
              position: absolute;
              left: 0;
              width: 4px;
              height: 100%;
              top: 0;
              background: #ff8502;
            }
          }

          &.active {
            color: #ff8502;
          }
        }
      }
    }
  }

  .bar {
    &.LMonthSelector {
      background: #f5f5f5;
    }
  }
</style>
