<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
  export default {
    name: 'app',
    mounted(){
      console.log(process.env, 'process.env')
    }
  }
</script>

<style lang="scss">
  @import './css/common/common';
  @import './css/custom/equalpay';
</style>
