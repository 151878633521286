<template>
  <div>
    <l-checkbox
      v-if="information.bool !== null"
      v-model="information.bool"
      :label="information.label"
      :readonly="true"
    ></l-checkbox>
    <l-preformat
      v-if="information.text !== null"
      v-model="information.text"
      :label="information.label"
    ></l-preformat>
    <l-text
      v-if="information.date !== null"
      v-model="information.date"
      :label="information.label"
      :readonly="true"
      :filter="this.$options.filters.dateShort"
    ></l-text>
  </div>
</template>

<script>
  export default {
    name: 'InformationView',
    props: ['information']
  }
</script>
