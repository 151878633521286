<template>
    <div class="fristenPage" >
      <header class="bar">
        <div class="bar-area-left">
          <h1>Fristen</h1>
        </div>
        <div class="bar-area-right">
          <l-checkbox v-model="monthMode" label="Monatsmodus zeigen"/>
          <span class="select-prefix">Filter: </span>
          <l-select
            :elements="filterItems"
            :elementLabel="x => x.label"
            v-model="selectedFilter"
            :initialValue="filterItems[0]"
          />
          <l-text class="filter filter-input-with-search-icon" v-model="filterText" placeholderLabel="Suche ..." :delay="delay" />
        </div>
      </header>
      <div class="notification">Die aufgeführten Information dienen der Orientierung. Sie bieten einen Anhaltspunkt zur Berechnung des Eintrittszeitpunkts von Equal-Pay oder der Höchstüberlassungsdauer gem. AÜG. Sie erheben keinesfalls Anspruch auf Vollständig- oder Korrektheit.</div>
      <div class="fristen-list" v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-distance="100">
        <div class="spinnerContent" v-if="isLoading">
          <h1 class="hint">Lade Daten</h1>
          <div class="spinner"></div>
        </div>
        <div v-else>
          <div v-if="employees.length > 0" >
            <hr class="first" title="Grenze EqualPay"/>
            <hr class="second" title="Grenze Höchstüberlassungsdauer"/>
            <employee-frist
              v-for="employee in employees"
              :key="employee.id"
              :employee="employee"
            />
          </div>
          <div v-else>
            <h4 class="noItems">Für den gewählten Filter sind keine Einträge vorhanden</h4>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
    import EmployeeFrist from './components/EmployeeFrist.vue'
    import infiniteScroll from 'vue-infinite-scroll'
  
    export default {
      components: {
        EmployeeFrist
      },
      directives: {
        infiniteScroll
      },
      name: 'fristenPage',
      data () {
        return {
          maxCount: 50,
          delay: 500,
          busy: false
        }
      },
      created () {
        this.$store.dispatch('fristen/getDataFromServer')
      },
      computed: {
        monthMode: {
          get () { return this.$store.getters['fristen/getMonthMode'] },
          set (value) { this.$store.commit('fristen/setMonthMode', value) }
        },
        selectedFilter: {
          get () { return this.$store.getters['fristen/getSelectedFilter'] },
          set (value) { this.$store.commit('fristen/setSelectedFilter', value) }
        },
        filterText: {
          get () { return this.$store.getters['fristen/getFilterText'] },
          set (value) { this.$store.commit('fristen/setFilterText', value) }
        },
        employees () {
          const employees = this.$store.getters['fristen/getEmployees']
          let count = 0
          return employees.filter(employee => count++ < this.maxCount)
        },
        filterItems () {
          return this.$store.getters['fristen/getFilterItems']
        },
        isLoading () {
          return this.$store.getters['fristen/isLoading']
        }
      },
      watch: {
        selectedFilter () {
          this.maxCount = 50
        }
      },
      methods: {
        logUpdate (start, end) {
          console.log('Updated', start, end)
        },
        loadMore () {
          console.log('LoadMore called')
          this.maxCount += 50
        }
      },
      beforeUpdate () {
        console.time('Updating')
      },
      updated () {
        console.timeEnd('Updating')
      }
    }
  </script>
  
  <style lang="scss">
    @import '../css/common/common.scss';
    .fristenPage {
      display: flex;
      flex-direction: column;
      width: 100%;
  
      > header {
        background: #f5f5f5;
        padding: 0 24px;
        flex: 0 0 auto;
  
        .bar-area-left {
          flex: 0 1 auto;
        }
  
        .bar-area-right {
          > * {
            margin-bottom: 0;
  
            &:not(:last-child):not(.select-prefix) {
              margin-right: 24px;
            }
          }
  
          .filter {
            .input-wrapper input {
              border-color: transparent !important;
            }
          }
        }
  
        h1 {
          text-transform: uppercase;
          color: #40667D;
        }
  
        .select-prefix {
          margin-right: 0.5em;
          display: inline-block;
        }
  
        .l-select,
        .lv-input-group-textfield,
        .lv-input-group-checkbox {
          min-width: 200px;
        }
  
        .l-select {
          input {
            border-bottom: none;
            color: #656565;
          }
        }
      }
  
      .scroller {
        height: calc(100vh - 48px);
      }
  
      .fristen-list {
        flex: 1 1 100%;
        overflow-y: auto;
        overflow-x: hidden;
        width: 100%;
      }
  
      .notification {
        padding: 10px;
        text-align: center;
        min-height: 55px;
        border: 1px solid rgba(0,0,0,0.1);
        color: darkgray;
        background-color: antiquewhite;
      }
  
      .first {
        top: 95px;
        left: calc(290px + (100% - 390px) / 2);
        position: absolute;
        bottom: 0;
        width: 0;
        border: 1px dashed black;
        z-index: 500;
      }
  
      .second {
        position: absolute;
        top:95px;
        left:calc(100% - 50px);
        bottom: 0;
        width: 0;
        border: 1px dashed red;
        z-index: 500;
      }
  
      .noItems {
        margin-top: 50px;
        text-align: center;
      }
  
      .spinnerContent {
        margin-top: 100px;
        > h1 {
          text-align: center;
          width: 100%;
        }
        .spinner {
          margin: auto;
          width: 50px;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          @extend .icon-loader;
          &:after {
            @extend .icon-spinning;
          }
        }
      }
    }
  
  
  
  </style>
  