import Vue from 'vue'

export default {
  name: 'service',
  namespaced: true,
  state: {
    services: {},
    serviceList: []
  },
  mutations: {
    clear (state) {
      state.serviceList = []
      state.services = {}
    },
    setServices (state, services) {
      state.services = {}
      state.serviceList = []
      services.forEach((service) => {
        Vue.set(state.services, service.offer_id, service)
        state.serviceList.push(service.offer_id)
      })
    }
  },
  getters: {
    getServices: state => state.serviceList.map(id => state.services[id]),
    getService: state => serviceId => state.services[serviceId]
  }
}
