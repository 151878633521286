<script>
  const getWidth = (frist, monthMode) => {
    if (!frist) {
      return '0'
    }
    const width = monthMode
      ? frist.months_without_cooldown * 100 / 18
      : frist.days_without_cooldown * 100 / (18 * 30)
    return width + '%'
  }
  const getTitle = (frist, monthMode) => {
    if (!frist) {
      return ''
    }
    return monthMode
      ? frist.months_without_cooldown + '/18 Monate'
      : frist.days_without_cooldown + '/' + 18 * 30 + ' Tage'
  }

  export default {
    functional: true,
    name: 'FristBar',
    render (createElement, {props}) {
      return createElement(
        'div',
        {
          'class': 'fristBar'
        },
        [
          createElement(
            'div',
            {
              style: {
                width: getWidth(props.frist, props.monthMode)
              },
              domProps: {
                title: getTitle(props.frist, props.monthMode)
              }
            }
          ),
          props.frist
           ? createElement(
             'span',
             [
               props.showLabel
                 ? createElement('span', props.frist.customer_label)
                 : undefined,
               props.showLabel
                 ? createElement('span', '(')
                 : undefined,
               createElement('span', getTitle(props.frist, props.monthMode)),
               props.showLabel
                 ? createElement('span', ')')
                 : undefined
             ])
           : undefined
        ]
      )
    }
  }
</script>



<style lang="scss">
  @import '../../css/common/colors';

  $offset: 254px;
  $padding: 48px;

  .fristBar {
    display: inline-block;
    position: absolute;
    left: $offset;
    top: 0;
    width: calc(100% - #{$offset} - #{$padding});
    height: 100%;

    > div {
      display: block;
      background: rgba($color_green, 1);
      // border-radius: 0.2em;
      box-sizing: border-box;

      position: absolute;
      left: 0;
      top: 50%;
      height: 100%; //1.8em;
      width: 0;
      transform: translateY(-50%);
    }

    > span {
      position: absolute;
      top: 0;
      display: flex;
      align-items: center;
      height: 100%;
      padding-left: 0.5em;
      color: rgba($color_black, 1);
      user-select: none;
      pointer-events: none;
      cursor: default;

      > * {
        font-size: 0.9em;

        &:first-child {
          padding-right: 0.4em;
        }
      }
    }
  }
</style>