<template>
  <div class="tarifvertrag">
    <div
      class="content"
      v-if="getActiveBereich">
        <tarif-vertrag-selector></tarif-vertrag-selector>
        <div class="tab-content">
          <h4>{{ getRegelarbeitszeitString }}</h4>
          <verguetung-table
            v-for="verguetung in getVerguetungen"
            :key="'v-' + verguetung.id"
            :verguetungId="verguetung.id"
          ></verguetung-table>
          <h2 v-if="getVerguetungen.length > 0">Details</h2>
          <information-view
            v-for="information in getInformations"
            :key="'i-' + information.id"
            :information="information"
          ></information-view>
        </div>
    </div>
  </div>
</template>

<script>
  import TarifVertragSelector from './TarifVertragSelector.vue'
  import VerguetungTable from './VerguetungTable.vue'
  import InformationView from './InformationView.vue'

  export default {
    components: {
      InformationView,
      VerguetungTable,
      TarifVertragSelector
    },
    name: 'tarifvertragView',
    computed: {
      getVerguetungen () {
        return this.$store.getters['verguetung/getVerguetungen']
      },
      getInformations () {
        return this.$store.getters['information/getInformations']
      },
      getActiveBereich () {
        return this.$store.getters.getActiveBereichId
      },
      getRegelarbeitszeitString () {
        const activeVertragId = this.$store.getters.getActiveTarifvertragId
        const activeVertrag = this.$store.getters['tarifvertrag/getTarifvertrag'](activeVertragId)
        if (!activeVertrag) {
          return
        }
        let workhours = activeVertrag.workhours
        return workhours.type === 'perWeek'
          ? 'Vertragliche Regelarbeitszeit: ' + workhours.perWeek + 'h pro Woche, rechnerisch ' + workhours.perMonth + 'h pro Monat'
          : 'Vertragliche Regelarbeitszeit: ' + workhours.perMonth + 'h pro Monat, rechnerisch ' + workhours.perWeek + 'h pro Woche'
      }
    }
  }
</script>

<style lang="scss">
  .tarifvertrag {
    position: relative;

    .content {
      position: absolute;
      top: 24px;
      left: 24px;
      right: 24px;
      bottom: 24px;
      height: auto;
      width: auto;
    }
  }
</style>
