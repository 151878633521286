export default {
  install (Vue, options) {
    // Set numeral local

    window.numeral.register('locale', 'de', {
      delimiters: {
        thousands: '.',
        decimal: ','
      },
      currency: {
        symbol: '€'
      }
    })
    window.numeral.locale('de')

    // Define format namespace

    Vue.prototype.$Format = {}

    // Prices

    Vue.prototype.$Format.price = function (value, withoutUnit) {
      let format = withoutUnit
        ? '0,0[.]00'
        : '0,0[.]00 $'

      return window.numeral(value).format(format)
    }

    // Percent

    Vue.prototype.$Format.percent = function (value, withoutUnit) {
      let format = withoutUnit
        ? '0,0[.]00'
        : '0,0[.]00'

      return window.numeral(value).format(format) + ' %'
    }

    // Dates

    Vue.prototype.$Format.date = function (isoOrMoment, format) {
      return isoOrMoment instanceof window.moment
        ? isoOrMoment.format(format)
        : window.moment(isoOrMoment).format(format)
    }

    Vue.prototype.$Format.dateShort = function (isoOrMoment) {
      return this.date(isoOrMoment, 'DD.MM.YYYY')
    }

    Vue.prototype.$Format.dateLong = function (isoOrMoment) {
      return this.date(isoOrMoment, 'DD. MMMM YYYY')
    }

    Vue.prototype.$Format.dateExtensive = function (isoOrMoment) {
      return this.date(isoOrMoment, 'dddd, DD. MMMM YYYY')
    }

    // Filters
    // Code duplication is strong with this one. There must be a way to dry.

    Vue.filter('price', function (value, withoutUnit) {
      let format = withoutUnit
        ? '0.0[,]00'
        : '0.0[,]00 $'

      return window.numeral(value).format(format)
    })

    Vue.filter('dateShort', function (isoOrMoment) {
      let format = 'DD.MM.YYYY'

      return isoOrMoment instanceof window.moment
        ? isoOrMoment.format(format)
        : window.moment(isoOrMoment).format(format)
    })
  }
}
