<template>
  <div id="twinki">
    <h1>Playground Twinkler</h1>
    <h2>MNSTR</h2>
    <vmnstr :elements="getStoreElements" :elementChildren="getElementChildren" ref="vmnstr">
      <div slot-scope="element" class="cell-renderer" @click="onClickCell(element)" :class="{hasChildren : element.children.length > 0}">
      {{element.directId}}: {{element.name}}
      </div>
    </vmnstr>
  </div>
</template>

<style lang="scss">
  .vmnstr {
    .mnstr {
      max-height: 300px;

      .mnstr-cell {
        border-bottom: 1px solid #ddd;

        &[data-level="1"] {
          padding-left: 20px;
        }
      }
    }
  }

  .cell-renderer {
    padding: 10px 5px;
    cursor: default;
    user-select: none;

    &:hover {
      background: #f0f0f0;
    }

    &.hasChildren {
      color: orange;
      cursor: pointer;
    }
  }
</style>

<script>
  import VMNSTR from '../plugins/MNSTR.vue'

  export default {
    name: 'PlayGroundTwinkler',

    components: {
      'vmnstr': VMNSTR
    },

    computed: {
      getStoreElements () {
        return this.$store.getters['play/getItems']
      }
    },

    methods: {
      getElementChildren (element, mnstr) {
        return element.children
      },

      onClickCell (element) {
        element.children && element.children.length > 0
          ? this.$refs.vmnstr.toggleExpandElement(element)
          : void 0
      }
    }
  }
</script>
