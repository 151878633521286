import Vue from 'vue'
import Vuex from 'vuex'

import bereich from './bereichStore'
import organisation from './organisationStore'
import tarifvertrag from './tarifvertragStore'
import verguetung from './verguetungStore'
import information from './informationsStore'
import viewState from './viewStateStore'
import fristen from './fristenStore'
import employee from './employeeStore'
import qualification from './qualificationStore'
import play from './playStore'
import payInfoPage from './payInfoPageStore'
import user from './userStore'

import DataGenerator from '../plugins/dataGenerator'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    bereich,
    organisation,
    tarifvertrag,
    verguetung,
    information,
    viewState,
    fristen,
    employee,
    qualification,
    play,
    payInfoPage,
    user,
  }
})

store.watch(
  (state) => state.organisation.activeOrganisation,
  (oldValue, newValue) => {
    store.dispatch('bereich/resetActiveBereich')
  }
)

function updateConnectionStatus () {
  store.commit('online')
}

window.addEventListener('online', updateConnectionStatus)
window.addEventListener('offline', updateConnectionStatus)

let generator = new DataGenerator()
let data = generator.generate()
store.commit('play/addItems', data)

export default store
