<template>
  <div>
    <vue-good-table
      :title="tableLabel"
      :columns="columns"
      :rows="rows"
      :paginate="false"
      :lineNumbers="false"
      :responsive="false">
      <template slot="table-column" slot-scope="props">
        <span @mouseover="showDescription(props.column.description)">
            {{props.column.label}}
         </span>
      </template>
      <template slot="table-row" slot-scope="props">
        <td
          v-for="(cell, key) in filtered(props.row)"
          @mouseover="showCellDetails(key, props.row)"
        >{{ getLabel(cell) }}</td>
      </template>
    </vue-good-table>
    <entgelt-gruppe-description
      :qualificationIds="qualificationIds"
      :description="description"
      :stufe="stufe"
      :entgeltGruppe="entgeltGruppe"
    ></entgelt-gruppe-description>
  </div>
</template>

<script>
  import EntgeltGruppeDescription from '../EntgeltPage/components/EntgeltGruppenDescription.vue'
  import numeral from 'numeral'

  export default {
    components: {EntgeltGruppeDescription},
    name: 'VerguetungView',
    props: {
      tableLabel: {
        type: String,
        default: ''
      },
      columns: {
        type: Array,
        required: true
      },
      rows: {
        type: Array,
        required: true
      }
    },
    data: () => {
      return {
        description: '',
        qualificationIds: [],
        stufe: '',
        entgeltGruppe: ''
      }
    },
    methods: {
      showDescription (description) {
        this.description = description
      },
      showCellDetails (key, row) {
        this.description = this.columns.filter((column) => column.field === key)[0].description
        this.stufe = this.columns.filter((column) => column.field === key)[0].label
        this.entgeltGruppe = row
          ? row.field0.label
          : ''
        this.qualificationIds = row
          ? row.field0.qualification_ids
          : []
      },
      filtered (items) {
        let clone = Object.assign({}, items)
        delete clone.id
        delete clone.originalIndex
        return clone
      },
      getLabel (cell) {
        if (cell.value) {
          return cell.entgelt_type === 'absolute'
            ? numeral(cell.value).format('0,0[.]00 $')
            : numeral(cell.value).format('0,0[.]00') + ' %'
        } else {
          return cell.label
        }
      }
    }
  }
</script>
