<template>
  <div class="selector">
    <label
      v-for="tarif in getTarifvertraege"
      :key="tarif.id"
      v-on:click="selectTarifVertrag(tarif.id)"
      :class="activeTarifvertragId === tarif.id? 'active' : ''"
    >
      {{  tarif.valid_from | dateShort }}
    </label>
  </div>
</template>

<script>
  export default {
    name: 'TarifVertragSelector',
    computed: {
      getTarifvertraege () {
        return this.$store.getters['tarifvertrag/getTarifvertraege']
      },
      activeTarifvertragId () {
        return this.$store.getters.getActiveTarifvertragId
      }
    },
    methods: {
      selectTarifVertrag (tarifVertragId) {
        this.$store.commit('setActiveTarifvertragId', tarifVertragId)
      }
    }
  }
</script>

<style lang="scss">
</style>
