<template>
  <div class="row-el">
    <!-- @slot Default Slot -->
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: 'LRow'
  }
</script>

<style lang="scss">
  .row-el {
    display: flex;
    width: 100%;
  }
</style>
