import LFlex from './layout/LFlex.vue'
import LRow from './layout/LRow.vue'

import LButton from './ui/LButton.vue'
import LCalendar from './ui/LCalendar.vue'
import LCheckbox from './ui/LCheckbox.vue'
import LDateRangeSelector from './ui/LDateRangeSelector.vue'
import LDialog from './ui/LDialog.vue'
import LList from './ui/LList.vue'
import LMonthPicker from './ui/LMonthPicker.vue'
import LMonthSelector from './ui/LMonthSelector.vue'
import LNumber from './ui/LNumber.vue'
import LPreformat from './ui/LPreformat.vue'
import LSelect from './ui/LSelect.vue'
import LText from './ui/LText.vue'
import LTextarea from './ui/LTextarea.vue'

const lovuely = {
  install (Vue, options) {
    Vue.component(LFlex.name, LFlex)
    Vue.component(LRow.name, LRow)

    Vue.component(LButton.name, LButton)
    Vue.component(LCalendar.name, LCalendar)
    Vue.component(LCheckbox.name, LCheckbox)
    Vue.component(LDateRangeSelector.name, LDateRangeSelector)
    Vue.component(LDialog.name, LDialog)
    Vue.component(LList.name, LList)
    Vue.component(LMonthPicker.name, LMonthPicker)
    Vue.component(LMonthSelector.name, LMonthSelector)
    Vue.component(LNumber.name, LNumber)
    Vue.component(LPreformat.name, LPreformat)
    Vue.component(LSelect.name, LSelect)
    Vue.component(LText.name, LText)
    Vue.component(LTextarea.name, LTextarea)
  }
}

export default lovuely
