import 'intersection-observer'
import Vue from 'vue'
import App from './App.vue'
import VueGoodTable from 'vue-good-table'
import VueVirtualScroller from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'

import store from './stores'
import router from './router.js'
import Lovuely from './lovuely'

import moment from 'moment'
import numeral from 'numeral'
import Formatter from './plugins/formatter'

window.moment = moment
window.numeral = numeral

Vue.use(VueGoodTable)
Vue.use(VueVirtualScroller)
Vue.use(Lovuely)
Vue.use(Formatter)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
