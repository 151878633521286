<template>
  <div
    class="lv-input-group lv-input-group-textfield"
    v-bind:class="{ focus: hasFocus, readonly: readonly }"
    v-on:click="onClick">
      <label class="title">{{ label }}</label>
      <div class="input-wrapper">
        <input type="text"
               ref="input"
               :value="value"
               :placeholder="placeholderLabel"
               @keyup="onKeyUp"
               v-on:focus="onFocus"
               v-on:blur="onBlur"
               v-bind:readonly="readonly"
        >
      </div>
  </div>
</template>

<script>
  const debounceEvent = (func, time) => {
    let interval
    return (...args) => {
      clearTimeout(interval)
      interval = setTimeout(() => {
        interval = null
        func(...args)
      }, time)
    }
  }

  export default {
    name: 'LText',
    props: {
      initialValue: {
        type: String,
        default: void 0
      },
      placeholderLabel: {
        type: String,
        default: void 0
      },
      label: {
        String,
        default: void 0
      },
      readonly: {
        default: false,
        type: Boolean
      },
      delay: {
        default: 0,
        type: Number
      }
    },
    created () {
      this.onKeyUp = debounceEvent(() => {
        this.value = this.$refs.input.value
        this.$emit('input', this.value, this)
      }, this.delay)
    },
    data () {
      return {
        value: this.initialValue,
        hasFocus: false
      }
    },

    methods: {
      onClick (e) {
        this.$emit('click', e, this)
      },
      onFocus (e) {
        this.hasFocus = true
        this.$emit('focus', e, this)
      },
      onBlur (e) {
        this.hasFocus = false
        this.$emit('blur', e, this)
      },
      focus () {
        this.$refs.input.focus()
      },
      blur () {
        this.$refs.input.blur()
      },
      getValue () {
        return this.value
      },
      setValue (value) {
        this.value = value
        this.$refs.input.value = this.value
      },
      isFocus () {
        return this.hasFocus
      }
    }
  }
</script>

<style lang="scss">
  .lv-input-group-textfield {
    input::-webkit-input-placeholder { color:#aaa; }
    input:-moz-placeholder { color:#aaa; }
    input::-moz-placeholder { color:#aaa; }
    input:-ms-input-placeholder { color:#aaa; }

    input:focus::-webkit-input-placeholder { color:transparent !important; }
    input:focus:-moz-placeholder { color:transparent !important; }
    input:focus::-moz-placeholder { color:transparent !important; }
    input:focus:-ms-input-placeholder { color:transparent !important; }
  }
</style>
