import moment from '../plugins/moment_de'
import EqualPayAPI from '../api/EqualPayAPI'
import service from './payInfo/serviceStore'
import employee from './payInfo/employeeStore'
import qualification from './payInfo/qualificationStore'
import tarifvertrag from './payInfo/tarifvertragStore'

export default {
  modules: {
    service,
    employee,
    qualification,
    tarifvertrag
  },
  name: 'payInfoPage',
  namespaced: true,
  state: {
    selectedMonth: moment().utc().subtract(1, 'month').startOf('month'),
    selectedEmployeeId: undefined,
    selectedServiceId: undefined,
    selectedService: undefined,
    isLoadingEmployees: false,
    isLoadingServices: false,
    isLoadingTarifvertrag: false
  },
  mutations: {
    setSelectedMonth (state, month) {
      state.selectedMonth = month
    },
    setSelectedEmployeeId (state, employeeId) {
      state.selectedEmployeeId = employeeId
    },
    setSelectedServiceId (state, serviceId) {
      state.selectedServiceId = serviceId
    },
    setSelectedService(state, service) {
      state.selectedService = service
    },
    setIsLoadingEmployees (state, value) {
      state.isLoadingEmployees = value
    },
    setIsLoadingServices (state, value) {
      state.isLoadingServices = value
    },
    setIsLoadingTarifvertrag (state, value) {
      state.isLoadingTarifvertrag = value
    }
  },
  getters: {
    getSelectedMonth: state => state.selectedMonth,
    getSelectedEmployeeId: state => state.selectedEmployeeId,
    getSelectedServiceId: state => state.selectedServiceId,
    getSelectedService: state => state.selectedService,
    getIsLoadingEmployees: state => state.isLoadingEmployees,
    getIsLoadingServices: state => state.isLoadingServices,
    getIsLoadingTarifvertrag: state => state.isLoadingTarifvertrag
  },
  actions: {
    loadEmployees ({commit, getters}) {
      let month = getters.getSelectedMonth
      commit('setIsLoadingEmployees', true)
      commit('employee/clear')
      commit('qualification/clear')
      commit('qualification/clear')
      commit('service/clear')
      commit('tarifvertrag/clear')
      EqualPayAPI.getEmployeesForMonth(month.format('YYYY-MM'))
        .then((data) => {
          commit('employee/setEmployees', data.data.employees)
          commit('qualification/setQualifications', data.data.qualifications)
          commit('setIsLoadingEmployees', false)
        })
    },
    loadServices ({commit, getters}, employeeId) {
      let month = getters.getSelectedMonth
      commit('setIsLoadingServices', true)
      commit('setSelectedEmployeeId', employeeId)
      commit('service/clear')
      commit('tarifvertrag/clear')
      EqualPayAPI.getServiceBucketsForEmployeeForMonth(month.format('YYYY-MM'), employeeId)
        .then((data) => {
          commit('service/setServices', data.data)
          commit('setIsLoadingServices', false)
        })
    },
    loadTarifVertrag ({commit, getters}, service) {
      commit('setSelectedServiceId', service.offer_id)
      commit('setSelectedService', service)
      commit('setIsLoadingTarifvertrag', true)
      commit('tarifvertrag/clear')
      EqualPayAPI.getTarifvertragForOffer(service.offer_id)
        .then(data => {
          console.log(data.data)
          commit('setIsLoadingTarifvertrag', false)
          commit('tarifvertrag/setTarifvertraege', data.data.tarifvertraege)
        })
    }
  }
}
