import Vue from 'vue'

export default {
  name: 'employee',
  namespaced: true,
  state: {
    employees: {},
    employeeList: []
  },
  mutations: {
    clear (state) {
      state.employeeList = []
      state.employees = {}
    },
    setEmployees (state, employees) {
      state.employees = {}
      state.employeeList = []
      employees.forEach((employee) => {
        Vue.set(state.employees, employee.id, employee)
        state.employeeList.push(employee.id)
      })
    }
  },
  getters: {
    getEmployees (state) {
      return state.employeeList
        .map((id) => state.employees[id])
    }
  }
}
