<template>
  <div>
     <l-select
      :elements="qualifications"
      :elementLabel="getLabelString"
      itemValue="id"
      description="Qualifikationsfilter"
      noValueLabel="Alle zeigen"
      v-model="selectedQuali"
    ></l-select>
    <vue-good-table
      :title="tableLabel"
      :columns="columns"
      :rows="rows"
      :paginate="false"
      :lineNumbers="false"
      :responsive="false">
      <template slot="table-column" slot-scope="props">
        <span @mouseover="showDescription(props.column.description)">
            {{props.column.label}}
         </span>
      </template>
      <template slot="table-row" slot-scope="props">
        <td
          v-for="(cell, key) in filtered(props.row)"
          @mouseover="showCellDetails(key, props.row)"
          :key="cell.id"
          v-html="getLabel(cell)"
        ></td>
      </template>
    </vue-good-table>
     <entgelt-gruppe-description
      :qualificationIds="qualificationIds"
      :description="description"
      :stufe="stufe"
      :entgeltGruppe="entgeltGruppe"
    ></entgelt-gruppe-description>
    <hr><br>
  </div>
</template>

<script>
  import EntgeltGruppeDescription from './EntgeltGruppenDescription.vue'
  import numeral from 'numeral'

  export default {
    components: {
      EntgeltGruppeDescription
    },
    name: 'VerguetungTable',
    props: ['verguetungId'],
    data: () => {
      return {
        description: '',
        qualificationIds: [],
        stufe: '',
        entgeltGruppe: '',
        selectedQuali: 0
      }
    },
    computed: {
      tableLabel () {
        return this.$store.getters['verguetung/getLabel'](this.verguetungId)
      },
      columns () {
        return this.$store.getters['verguetung/getColumns'](this.verguetungId)
      },
      rows () {
        console.log('Row berechnet')
        const id = this.selectedQuali && this.selectedQuali.id
          ? this.selectedQuali.id
          : undefined
        return this.$store.getters['verguetung/getRows'](this.verguetungId, id)
      },
      qualifications () {
        return this.$store.getters['qualification/getQualifications']
      }
    },
    methods: {
      getLabelString: item => item.name,
      showDescription (description) {
        this.description = description
      },
      showCellDetails (key, row) {
        this.description = this.columns.filter((column) => column.field === key)[0].description
        this.stufe = this.columns.filter((column) => column.field === key)[0].label
        this.entgeltGruppe = row
          ? row.field0.label
          : ''
        this.qualificationIds = row
          ? row.field0.qualification_ids
          : []
      },
      filtered (items) {
        let clone = Object.assign({}, items)
        delete clone.id
        delete clone.originalIndex
        return clone
      },
      getLabel (cell) {
        if (cell.value) {
          return cell.entgelt_type === 'absolute'
            ? numeral(cell.value).format('0,0[.]00 $')
            : numeral(cell.value).format('0,0[.]00') + ' %'
        } else if(cell.deviating_hierarchy_label) {
          return `
            <div class="deviating_hierarchy_cell">
              <div>${cell.label}</div>
              <div>${cell.deviating_hierarchy_label}</div>
            </div>
          `
        }else{
          return cell.label
        }
      }
    }
  }
</script>

<style lang="scss">
  .good-table {
    width: 100%;
    overflow-x: auto;

    td {
      min-width: 140px;
    }
  }
  .deviating_hierarchy_cell{
    display: flex;
    align-items: center;
    justify-content: start;

    div:nth-child(1){
          white-space: nowrap;
    }

    div:nth-child(2){
    font-size: 0.8rem;
    color: #b7b7b7;
    margin-left: 10px;
    }
  }
</style>
