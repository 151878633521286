<template>
  <div class="employee-selector">
    <label v-if="isLoading">Lade Mitarbeiter ...</label>
    <div v-else class="content">
      <!--<l-select
        :elements="sorters"
        :elementLabel="(element) => {return element.label}"
        :initialValue="sorters[0]"
        v-on:change="(element) => {this.selectedSorter = element.value}"
      />
      <label>{{ selectedBucket }}</label>-->
      <l-text
        class="filter filter-input-with-search-icon"
        v-model="filterText"
        placeholderLabel="Suche ..."
      />
      <ul class="tablist list-employees">
        <li
          class="tablist-item"
          :class="{active: selectedEmployee === employee}"
          v-for="employee in employees"
          :key="employee.id"
          v-on:click="setSelectedEmployee(employee)"
        >{{ employee.name }}, {{ employee.firstname }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
  const SORT_BY_NAME = 1
  const SORT_BY_QUALIFICATION = 2

  export default {
    name: 'EmployeeSelector',
    data () {
      return {
        selectedBucket: 'A',
        selectedEmployee: undefined,
        selectedSorter: SORT_BY_NAME,
        sorters: [
          {
            label: 'Nachname',
            value: SORT_BY_NAME
          },
          {
            label: 'Qualifikation',
            value: SORT_BY_QUALIFICATION
          }
        ],
        filterText: ''
      }
    },
    computed: {
      employees () {
        let employees = this.$store.getters['payInfoPage/employee/getEmployees']
        let filterValues = this.filterText.split(' ').map(v => v.trim().toLocaleLowerCase())

        const isNameInFilter = (employee, filterValue) =>
          (employee.name + employee.firstname).toLocaleLowerCase().includes(filterValue)

        const doesEmployeeMatchFilter = employee =>
          (memo, filterValue) => memo && isNameInFilter(employee, filterValue)

        if (employees) {
          employees = filterValues.length
            ? employees.filter(employee => filterValues.reduce(doesEmployeeMatchFilter(employee), true))
            : employees

          switch (this.selectedSorter) {
            case SORT_BY_NAME:
              employees = employees.sort((a, b) => a.name.localeCompare(b.name))
              break
            case SORT_BY_QUALIFICATION:
              employees = employees.sort((a, b) => a.qualification_id > b.qualification_id)
              break
          }
          return employees
        } else return []
      },
      isLoading () {
        return this.$store.getters['payInfoPage/getIsLoadingEmployees']
      }
    },
    methods: {
      setSelectedEmployee (employee) {
        // ViewStateStore verwenden für den State?
        this.selectedEmployee = employee
        this.$store.dispatch('payInfoPage/loadServices', employee.id)
      }
    }
  }
</script>

<style lang="scss">
  .employee-selector {
    .tablist {
      .tablist-item {
        &:first-child {
          border-top: none;
        }
      }
    }

    .filter {
      padding: 0em;
      margin: 0;

      &:hover {
        background: rgba(0,0,0,0.025);
      }
    }
  }
</style>
