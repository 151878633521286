<template>
  <div
    class="lv-input-group lv-input-group-textfield"
    v-bind:class="{ focus: focus, readonly: readonly }">
      <label class="title">{{ label }}</label>
      <div class="input-wrapper">
        <input type="number"
               ref="numberInput"
               :value="value | output(this)"
               @input="update()"
               v-on:focus="onFocus"
               v-on:blur="onBlur"
               v-bind:readonly="readonly"
        >
      </div>
  </div>
</template>

<script>
  export default {
    name: 'LNumber',
    props: {
      value: Number,
      label: String,
      readonly: {
        default: false,
        type: Boolean
      },
      filter: {
        type: Function
      }
    },

    filters: {
      output (value, context) {
        return context.filter
          ? context.filter(value)
          : value
      }
    },

    data () {
      return {
        focus: false
      }
    },

    methods: {
      update () {
        this.$emit('input', parseInt(this.$refs.numberInput.value))
      },
      onFocus (e) {
        this.focus = !this.readonly && true
      },
      onBlur (e) {
        this.focus = !this.readonly && false
      }
    }
  }
</script>
