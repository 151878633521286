<template>
  <div class="gruppenDescription">
    <h6>{{ getHeading }}</h6>
    <label
        v-for="quali in qualificationIds"
        :key="quali"
      >{{getQualificationName(quali)}}</label>
  </div>
</template>

<script>
  export default {
    name: 'EntgeltGruppeDescription',
    props: {
      qualificationIds: {
        type: Array,
        default: []
      },
      description: {
        type: String,
        default: ''
      },
      stufe: {
        type: String,
        default: ''
      },
      entgeltGruppe: {
        type: String,
        default: ''
      }
    },
    computed: {
      getHeading () {
        return this.description
          ? 'Qualifikationen in Entgeltgruppe ' + this.entgeltGruppe + ' Stufe ' + this.stufe + ' (' + this.description + ')'
          : 'Qualifikationen in Entgeltgruppe ' + this.entgeltGruppe
      }
    },
    methods: {
      getQualificationName (qualiId) {
        let quali = this.$store.getters['qualification/getQualification'](qualiId)
        return quali
          ? quali.name
          : ''
      }
    }
  }
</script>

<style lang="scss">
  .gruppenDescription {
    margin-top: 10px;
    h6 {
      margin: 5px;
    }
    label {
      color: #888;
      font-family: Ubuntu, sans-serif;
      font-size: 14px;
      display: block;
    }
  }
</style>
