import EqualPayAPI from "../api/EqualPayAPI";

export default {
  name: 'user',
  namespaced: true,
  state: {
    data: {}, // all data related to the logged-in user, see mock_user_response.json for reference
  },
  mutations: {
    clearStore (state) {
      state.data = {}
    },
    setUserData (state, data) {
      state.data = data
    }
  },
  getters: {
    getUserInfo: state => state.data
  },
  actions: {
    getDataFromServer ({commit}) {
      commit('clearStore')
      EqualPayAPI.getLoggedInUser().then(({ data }) => {
        commit("clearStore");
        commit("setUserData", data)
      });
    }
  }
}
