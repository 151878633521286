<template>
  <div class="vmnstr" />
</template>
<script>
  import MNSTR from './mnstr.js'
  import Vue from 'vue'

  export default {
    name: 'VMNSTR',

    props: {
      /**
       * MNSTR properties and options.
       */

      elements: {
        type: Array,
        default: []
      },
      elementChildren: {
        type: Function,
        default: void 0
      },
      preventWheelBubbling: false,
      useTransform: true,
      initialScrollToElement: undefined,
      observeCellBounds: false,
      rememberChildrenExpands: true,

      /**
       * Wrapper options.
       */

      /**
       * When rendering components as cells it might be that the views need
       * to keep their states. Unfortunately, they are not cached by default.
       * When a cell is dismissed, its view will also be sent to GC. Unless
       * this flag is set to true, though.
       * Important: This cache will be flushed on every update event to
       * prevent memory leaks.
       */

      cacheVNodes: {
        type: Boolean,
        default: true
      }
    },

    data () {
      return {
        mnstr: void 0
      }
    },

    mounted () {
      this.vNodeCacheKeys = []
      this.vNodeCacheValues = []
      this.renderMNSTR()
    },

    updated () {
      this.updateMNSTR()
    },

    methods: {
      /**
       * Instantiate MNSTR.
       */

      renderMNSTR () {
        this.mnstr = new MNSTR({
          context: this,
          parentNode: this.$el,
          getData: function () {
            return this.elements
          }.bind(this),
          getCellRenderer: function (element) {
            return this.renderMNSTRCell(element)
          }.bind(this),
          getElementChildren: this.elementChildren
            ? function (element) {
              return this.elementChildren(element)
            }.bind(this)
            : void 0,
          preventWheelBubbling: this.preventWheelBubbling,
          useTransform: this.useTransform,
          initialScrollToElement: this.initialScrollToElement,
          observeCellBounds: this.observeCellBounds,
          rememberChildrenExpands: this.rememberChildrenExpands,
          didRenderFirstElement: this.onMNSTRRenderFirstElement,
          didRenderLastElement: this.onMNSTRRenderLastElement
        })
      },

      /**
       * Call this anytime elements or children or anything else
       * has changed. MNSTR will update itself accordingly. This will also cause
       * to flush the vnode cache to prevent having references to "dead" objects/vnodes.
       */

      updateMNSTR () {
        this.flushVNodeCache()
        this.mnstr.reset()
      },

      /**
       * The method creates a VNode from the default slot content and returns its HTMLElement.
       * I don't know how to do that properly, but generating a new Vue instance and
       * calling vue._update(VNode) does the trick. After that, the VNode has elm set,
       * which is what I need for MNSTR. It is important that the VNode is not appended to the
       * VDOM or its HTMLElement to the DOM. I just want its HTMLElement to be returned.
       */

      renderMNSTRCell (element) {
        let vnode

        if (this.cacheVNodes) {
          vnode = this.getCachedVNodeForElement(element)
        }

        if (!vnode) {
          const v = new Vue()
          vnode = this.$scopedSlots.default(element)
          v._update(vnode)

          this.cacheVNodes
            ? this.cacheVNodeWithElement(element, vnode)
            : void 0
        }

        return vnode.elm
      },

      /**
       * Wrapper methods to expand / collapse / toggle expand list elements.
       */

      expandElement (element) {
        this.mnstr.expandElement(element)
      },

      collapseElement (element) {
        this.mnstr.collapseElement(element)
      },

      toggleExpandElement (element) {
        this.mnstr.toggleExpandElement(element)
      },

      /**
       * Children vnode caching for preserving component states. Map elements to vnodes.
       * No hashing, just indexing. IndexOf is highly optimized and pretty fast,
       * although its O(n).
       * Caution: Caching vnodes will add references for both the element and the vnode to this
       * component instance for as long as the instance exists.
       */

      cacheVNodeWithElement (element, vnode) {
        const index = this.vNodeCacheKeys.indexOf(element)

        if (index === -1) {
          this.vNodeCacheKeys.push(element)
          this.vNodeCacheValues.push(vnode)
        } else {
          this.vNodeCacheValues[index] = vnode
        }
      },

      getCachedVNodeForElement (element) {
        const index = this.vNodeCacheKeys.indexOf(element)

        return index > -1
          ? this.vNodeCacheValues[index]
          : void 0
      },

      flushVNodeCache () {
        this.vNodeCacheKeys = []
        this.vNodeCacheValues = []
      },

      /**
       * Bubble MNSTR events to parent components.
       */

      onMNSTRRenderFirstElement (element, mnstr) {
        this.$emit('renderfirstelement', element, this)
      },

      onMNSTRRenderLastElement (element, mnstr) {
        this.$emit('renderlastelement', element, this)
      }
    }
  }
</script>

<style lang="scss">
  .vmnstr {
    .monsterlist {
      width: 100%;
      height: 100%;
    }
  }
</style>
