<template>
  <div
    class="LButton"
    v-on:click="onClicked"
  >
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: 'LButton',
    props: {
      text: {
        type: String,
        default: ''
      },
      icon: {
        type: String,
        default: ''
      }
    },
    methods: {
      onClicked () {
        console.log('Klicked')
        this.$emit('click')
      }
    }
  }
</script>

<style lang="scss">
  .LButton {
    height: auto;
    width: auto;
    flex: 0 0 auto;
    padding: 0.7em 1em;
    border: 1px solid #ccc;
    text-transform: uppercase;
    color: #656565;

    display: flex;
    align-items: center;
    justify-content: center;

    &.icon {
      width: 48px;
      flex: 0 0 48px;
    }

    &.stretch-height {
      height: 48px;
    }

    &:not(.icon) {
      font-size: 0.9em;
    }

    &.no-border {
      border: none;
    }

    &:hover {
      background: rgba(0,0,0,0.025);
      cursor: pointer;
      color: #ff8502;
    }
  }
</style>
