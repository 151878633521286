import axios from "axios";

export default {
  getOrganisations() {
    return axios.post(
      process.env.VUE_APP_BACKEND_BASE + "/EP/getOrganizations",
      null,
      { withCredentials: true }
    );
  },

  getBereiche(activeOrganisationId) {
    return axios.post(
      process.env.VUE_APP_BACKEND_BASE + "/EP/getBereiche",
      {
        organization_id: activeOrganisationId,
      },
      { withCredentials: true }
    );
  },

  getTarifvertraege(activeBereichId) {
    return axios.post(
      process.env.VUE_APP_BACKEND_BASE + "/EP/getTarifvertraege",
      {
        bereich_id: activeBereichId,
      },
      { withCredentials: true }
    );
  },

  getEmployeesForMonth(month) {
    return axios.post(
      process.env.VUE_APP_BACKEND_BASE + "/EP/getEmployeesForMonth",
      {
        month: month,
      },
      { withCredentials: true }
    );
  },

  getServiceBucketsForEmployeeForMonth(month, employeeId) {
    return axios.post(
      process.env.VUE_APP_BACKEND_BASE +
        "/EP/getServiceBucketsForEmployeeForMonth",
      {
        month: month,
        employee_id: employeeId,
      },
      { withCredentials: true }
    );
  },

  getTarifvertragForOffer(serviceId) {
    return axios.post(
      process.env.VUE_APP_BACKEND_BASE + "/EP/getTarifvertragForOffer",
      {
        offer_id: serviceId,
      },
      { withCredentials: true }
    );
  },

  getFristen() {
    if (process.env.VUE_APP_ENV === "development") {
      return axios.get("./mock_fristen_response.json");
    }
    return axios.post(
      process.env.VUE_APP_BACKEND_BASE + "/EP/getFristen",
      null,
      { withCredentials: true }
    );
  },

  getLoggedInUser() {
    if (process.env.VUE_APP_ENV === "development") {
      return axios.get("./mock_user_response.json");
    }
    return axios.get(process.env.VUE_APP_BACKEND_BASE + "/users/iam", null, {
      withCredentials: true,
    });
  },
};
