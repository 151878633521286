<template>
  <div class="L-DateRangeSelector">
    <span @click="decrease"> - </span>
    <label v-if="monthSelector">{{ monthString }}</label>
    <label v-else>
      <span
        @click="showStart= !showStart"
      >{{ startDate }}</span>
      <span> - </span>
      <span
        @click="showEnd= !showEnd"
      >{{ endDate }}</span>
    </label>
    <span @click="increase"> + </span>
    <div>{{errorMessage}}</div>
    <l-dialog
      :show="showStart"
      @close="closeStart"
    >
      Start Modal
      <button @click="showStart=!showStart">Close</button>
    </l-dialog>
    <l-dialog
      :show="showEnd"
      @close="closeEnd"
    >
      <l-calendar
        v-model="value.end"
      />
      <button @click="showEnd=!showEnd">Close</button>
    </l-dialog>

  </div>
</template>

<script>
  import LDialog from './LDialog.vue'
  import LCalendar from './LCalendar.vue'

  export default {
    components: {
      LCalendar,
      LDialog},
    name: 'LDateRangeSelector',
    data () {
      return {
        showStart: false,
        showEnd: false,
        date: {

        }
      }
    },
    props: {
      /**
       * @model
       * @desc Object containing start and end (both moment objects)
       */
      value: {
        type: Object,
        validator: (value) => {
          return value.start && value.end
        }
      },
      /**
       * show only month
       * /
      simple: {

      },
      /**
       * format string
       * @default 'dd.MM.YYYY
       */
      format: {
        type: String,
        default: 'DD MMMM YYYY'
      },
      /**
       *
       */
      monthStringFormat: {
        type: String,
        default: 'MMMM YYYY'
      },
      /**
       *
       */
      monthSelector: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      startDate () {
        return this.value.start.format(this.format)
      },
      endDate () {
        return this.value.end.format(this.format)
      },
      monthString () {
        return this.value.start.format(this.monthStringFormat)
      },
      errorMessage () {
        let msg = ''
        if (this.value.start.isAfter(this.value.end)) {
          msg = 'Start liegt nach Ende'
        }
        return msg
      }
    },
    methods: {
      decrease () {
        this.value.month--
        if (this.value.month < 1) {
          this.value.month = 12
          this.value.year--
        }
        this.$emit('input', this.value)
      },
      increase () {
        this.value.month++
        if (this.value.month > 12) {
          this.value.month = 1
          this.value.year++
        }
        this.$emit('input', this.value)
      },
      closeStart () {
        this.showStart = !this.showStart
      },
      closeEnd () {
        this.showEnd = !this.showEnd
      }
    }
  }
</script>

<style lang="scss">
  .L-DateRangeSelector {
    height: 40px;
    display: flex;
    border: 1px solid rgba(0,0,0,0.3);

    span {
      width: 40px;
      text-align: center;
      padding-top: 10px;
    }

    label {
      flex: 1;
      text-align: center;
      padding-top: 10px;
    }
  }
</style>
